/**
 * @TODO: This component should technically live in the component library.
 * However, we are still deciding how to reconcile it with our Autocomplete
 * and current Native version of the select. This version is a slight copy of Autocomplete
 */
import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import ChevronDownOutline from '@robinpowered/icons/ChevronDownOutline';
import Checkmark from '@robinpowered/icons/Checkmark';

import { useTheme } from '@emotion/react';
import {
  Body,
  BrownFontStack,
  Colors,
  SpinnerLoader,
  UtilityButton,
  useClickOutside,
  useEscape,
} from '@robinpowered/design-system';
import { FieldContainer, FieldByline, FieldError, FieldLabel } from '../Fields';

export type SelectOption = {
  value: string;
  label: string;
  id: string;
};

export interface SelectProps {
  /** The controlled value {value: '', label: ''}> If label omitted, the value is used */
  value: SelectOption;
  /** An array of options {value: '', label: ''} */
  options: SelectOption[];
  /** Sets the name and aria-label for the input */
  name: string;
  /** Label rendered on top of the input. */
  label?: string;
  // Takes a new value object
  onSelect: (value: SelectOption) => void;
  /** Text rendered in dropdown when there is no matching result */
  noResultsText?: string;
  /** Placeholder rendered within the input. */
  placeholder?: string;
  /** When the dropdown items are loading, renders a loader in the input */
  isLoading?: boolean;
  /** Style properties applied to the input's container */
  style?: React.CSSProperties | undefined;
  /** Byline rendered below the input. */
  byline?: string;
  /** Error rendered below the input. Will override the byline. */
  error?: string;
  /** Blocks user action. */
  disabled?: boolean;
}

export const Select = ({
  options,
  isLoading = false,
  onSelect,
  noResultsText,
  placeholder = 'Select',
  name,
  style,
  error,
  byline,
  disabled,
  label,
  value,
}: SelectProps): JSX.Element => {
  const { colorTokens } = useTheme();
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);
  useClickOutside(() => setOpen(false), containerRef);
  useEscape(() => setOpen(false));

  const canInteract = !disabled && !isLoading;

  return (
    <FieldContainer>
      {label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}

      <Container
        ref={containerRef}
        robinSize={'medium'}
        style={style}
        aria-disabled={disabled}
        disabled={disabled}
      >
        <Input
          readOnly
          robinSize={'medium'}
          disabled={disabled}
          name={name}
          aria-label={name}
          placeholder={placeholder}
          onClick={(): void => {
            if (canInteract) {
              setOpen((prev) => !prev);
            }
          }}
          value={value?.label || value.value}
          onChange={() => {
            if (!open && canInteract) {
              setOpen(true);
            }
          }}
        />

        {isLoading && (
          <>
            <LoaderContainer>
              <SpinnerLoader
                height={14}
                width={14}
                color={colorTokens.icon.default}
              />
            </LoaderContainer>
            <VerticalLine />
          </>
        )}

        {/* @TODO: Bug with the utility button we cant handle on enter to close dropdown  */}
        <UtilityButton
          // eslint-disable-next-line react/jsx-no-literals
          aria-label={'Open options list'}
          icon={ChevronDownOutline}
          style={{
            height: '28px',
            width: '28px',
            transform: `rotate(${open ? 180 : 0}deg)`,
          }}
          onClick={(): void => {
            if (canInteract) {
              setOpen(open ? false : true);
            }
          }}
        />
        {open && !isLoading && (
          <OptionMenu>
            {options.map((option, index) => (
              <OptionItem
                key={index}
                tabIndex={0}
                selected={option.id === value.id}
                onClick={(): void => {
                  onSelect({
                    value: option.value,
                    label: option.label,
                    id: option.id,
                  });
                  setOpen(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSelect({
                      value: option.value,
                      label: option.label,
                      id: option.id,
                    });
                    setOpen(false);
                  }
                }}
              >
                <OptionItemText as="p">{option.label}</OptionItemText>

                {option.id === value.id && (
                  <Checkmark
                    size={16}
                    color={Colors.Gray80}
                    style={{ marginLeft: 'auto' }}
                  />
                )}
              </OptionItem>
            ))}
            {!options.length && value && (
              <OptionItem>
                <Body.Small>{noResultsText || 'No results'}</Body.Small>
              </OptionItem>
            )}
          </OptionMenu>
        )}
      </Container>
      {!error && byline && <FieldByline>{byline}</FieldByline>}
      {error && <FieldError message={error} />}
    </FieldContainer>
  );
};

type ContainerProps = {
  robinSize: 'small' | 'medium';
  disabled?: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid
    ${({ theme, disabled }): string =>
      disabled ? theme.colorTokens.stroke.layer1 : Colors.Gray40};
  border-radius: 4px;
  cursor: text;
  padding: 4px;
  height: ${({ robinSize }): string =>
    robinSize === 'medium' ? '40px' : '32px'};
  background-color: ${({ theme, disabled }): string =>
    disabled ? theme.colorTokens.bg.layer2 : theme.colorTokens.main.white};
  &:focus-within {
    outline: none;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colorTokens.focus.focus};
  }
`;

type InputProps = {
  robinSize: 'small' | 'medium';
  disabled?: boolean;
  error?: boolean;
};

const Input = styled.input<InputProps>`
  margin-left: 6px;
  cursor: pointer;
  border: none;
  ${({ robinSize }) =>
    robinSize === 'medium' ? Body.Regular.styles : Body.Small.styles};
  flex: 1;
  color: ${({ theme, disabled }): string =>
    disabled ? theme.colorTokens.text.disabled : theme.colorTokens.text.body};
  background-color: ${({ theme, disabled }): string =>
    disabled ? theme.colorTokens.bg.layer2 : theme.colorTokens.main.white};

  &::placeholder {
    color: ${({ theme }): string => theme.colorTokens.text.disabled};
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }
`;

const VerticalLine = styled.span`
  height: 20px;
  margin: 4px;
  border-right: 1px solid
    ${({ theme }): string => theme.colorTokens.stroke.layer3};
`;

const OptionMenu = styled.ul(({ theme }) => ({
  border: `1px solid ${theme.colorTokens.stroke.layer3}`,
  backgroundColor: Colors.White0,
  borderRadius: '4px',
  boxShadow: '0 1px 2px 0 rgba(0,0,0,0.1)',
  boxSizing: 'border-box',
  fontFamily: BrownFontStack,
  fontSize: theme.fontSizes[2],
  left: 0,
  maxHeight: '300px',
  overflow: 'auto',
  position: 'absolute',
  top: '110%',
  userSelect: 'none',
  width: '100%',
  zIndex: 999,
}));

const OptionItem = styled.li<{ selected?: boolean }>(({ selected }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  background: `${selected ? Colors.Tan10 : 'transparent'}`,
  listStyle: 'none',

  '&:hover, &:focus': {
    background: `${Colors.Tan10}`,
  },
}));

const OptionItemText = styled(Body.Small)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
`;

const LoaderContainer = styled.div`
  right: 8px;
  top: 8px;
  width: 18px;
  height: 18px;
`;
