import React, { createContext, useContext } from 'react';
import { ApolloError } from '@apollo/client';
import {
  FacilitatorGroup,
  FacilitatorUser,
  usePersonGroupSearch,
} from '../../usePersonGroupSearch';
import { UseManageFacilitatorsAPI, useManageFacilitators } from '../hooks';

type SelectFacilitatorType = {
  users: FacilitatorUser[];
  groups: FacilitatorGroup[];
  keyword: string;
  setKeyword: (keyword: string) => void;
  error: ApolloError | undefined;
  loading: boolean;
  facilitatorType: 'assignees' | 'approvers';
} & UseManageFacilitatorsAPI;

const SelectFacilitatorContext = createContext<SelectFacilitatorType>({
  users: [],
  groups: [],
  keyword: '',
  error: undefined,
  loading: true,
  setKeyword: () => null,
  facilitatorType: 'assignees',
  groupFacilitator: undefined,
  individualFacilitator: undefined,
  showPersonGroupSearch: false,
  closePersonGroupSearch: () => null,
  setShowPersonGroupSearch: () => null,
  selectGroupFacilitator: () => null,
  selectIndividualFacilitator: () => null,
  removeGroupFacilitator: () => null,
  removeIndividualFacilitator: () => null,
});

export const SelectFacilitatorProvider = ({
  children,
  facilitatorType,
}: {
  children: JSX.Element;
  facilitatorType: 'assignees' | 'approvers';
}): JSX.Element => {
  const { keyword, setKeyword, users, groups, loading, error } =
    usePersonGroupSearch();
  const useManagersAPI = useManageFacilitators(setKeyword, facilitatorType);

  return (
    <SelectFacilitatorContext.Provider
      value={{
        users,
        groups,
        error,
        loading,
        keyword,
        setKeyword,
        facilitatorType,

        ...useManagersAPI,
      }}
    >
      {children}
    </SelectFacilitatorContext.Provider>
  );
};

export const useSelectFacilitatorContext = (): SelectFacilitatorType => {
  return useContext(SelectFacilitatorContext);
};
