import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useTranslation } from 'react-i18next';
import {
  AddMeetingServiceInput,
  useAddMeetingServiceMutation,
  useUpdateMeetingServiceByServiceFormMutation,
} from 'generated';
import { useToast } from 'contexts/ToastContext';
import {
  convertUITicketQuestionToAddMeetingServiceQuestionInput,
  mapMeetingServiceQueryToFormValues,
} from 'types';
import { useMeetingServicePageContext } from 'pages/MeetingServicePage/contexts/MeetingServicePageContext';

export const useManageServiceForm = (
  onSuccessfulSubmit: (status: 'added' | 'updated') => void
) => {
  const { t } = useTranslation('ServiceSection');
  const toast = useToast();
  const { getMeetingServiceByIdQuery, serviceId } =
    useMeetingServicePageContext();
  const [addMeetingService, { loading: savingMeetingService }] =
    useAddMeetingServiceMutation();

  const [updateMeetingService, { loading: updatingMeetingService }] =
    useUpdateMeetingServiceByServiceFormMutation();

  // If there is a conflict from multiple people trying to update services at the same time
  const [hasUpdateServiceConflict, setHasUpdateServiceConflict] =
    useState(false);

  const methods = useForm<MeetingServiceType>({
    defaultValues: {
      questions: [],
      category: { name: '', key: '', id: '' },
      description: '',
      serviceName: '',
      availableInSpacesByBuilding: new Map(),
      assignees: { users: [], groups: [] },
      approvers: undefined,
    },
  });

  useEffect(() => {
    if (
      !getMeetingServiceByIdQuery?.loading &&
      !getMeetingServiceByIdQuery?.error
    ) {
      methods.reset(
        mapMeetingServiceQueryToFormValues(getMeetingServiceByIdQuery?.data)
      );
    }
  }, [
    getMeetingServiceByIdQuery?.data,
    getMeetingServiceByIdQuery?.error,
    getMeetingServiceByIdQuery?.loading,
    methods,
  ]);

  const validatedData = useCallback(
    (data: MeetingServiceType) => {
      let validated = true;
      if (!data.category.id) {
        methods.setError('category.id', {
          type: 'required',
          message: t(`service_details.inputs.category.select.error`),
        });
        validated = false;
      }
      if (!data.serviceName) {
        methods.setError('serviceName', {
          type: 'required',
          message: t(`service_details.inputs.name.error`),
        });
        validated = false;
      }
      if (!data.assignees.groups.length && !data.assignees.users.length) {
        methods.setError('assignees', {
          type: 'required',
          message: t(`assignee.error`),
        });
        validated = false;
      }
      if (
        data.questions.length &&
        data.questions.some((question) => question.hasEmptyContent)
      ) {
        methods.setError('questions', { type: 'required' });
        validated = false;
      }

      if (
        data.availableInSpacesByBuilding &&
        [...data.availableInSpacesByBuilding.values()].some(
          (spaces) => spaces && spaces.size === 0
        )
      ) {
        methods.setError('availableInSpacesByBuilding', {
          type: 'required',
          message: t(`location.error`),
        });
        validated = false;
      }
      return validated;
    },
    [methods, t]
  );

  const onSubmit: SubmitHandler<MeetingServiceType> = async (data) => {
    validatedData(data);
    if (!Object.keys(methods.formState.errors).length) {
      const {
        category,
        serviceName,
        approvers,
        assignees,
        questions,
        description,
      } = data;

      const availableInSpacesByBuilding = [
        ...data.availableInSpacesByBuilding,
      ].map(([buildingId, spaces]) => ({
        buildingId,
        spaceIds: Array.from(spaces || []),
      }));

      // The buildings that the current service already had associated
      const loadedBuildings =
        getMeetingServiceByIdQuery?.data?.getMeetingServiceById.meetingService
          ?.availabilityInBuildings.buildingAvailability;

      // Check if any of the previously loaded buildings no longer exist
      // in state. If so, return them to the api as {buildingId, spaces: []}
      const deletedBuildings =
        loadedBuildings?.reduce<{ buildingId: string; spaceIds: string[] }[]>(
          (prev, curr) => {
            // If the building is found in the full list of loaded buildings
            const availableBuilding = availableInSpacesByBuilding?.find(
              (ab) => ab.buildingId === curr.buildingId
            );

            if (availableBuilding) {
              return [...prev];
            }

            return [...prev, { buildingId: curr.buildingId, spaceIds: [] }];
          },
          []
        ) || [];

      // If a building is submitted with 0 spaces it means the spaces
      // were not loaded into it yet (by opening the building) and actually has
      // no updates, so remove it from the changes call
      const buildingsWithoutChanges = availableInSpacesByBuilding.filter(
        (b) => b.spaceIds.length > 0
      );

      const availableInSpacesByBuildingChanges = [
        ...deletedBuildings,
        ...buildingsWithoutChanges,
      ];

      /* Shape we want passed to the add service mutation */
      const meetingServiceInput: AddMeetingServiceInput = {
        description,
        approvers:
          approvers?.groups[0]?.id || approvers?.users[0]?.id
            ? {
                groupId: approvers?.groups[0]?.id,
                userId: approvers?.users[0]?.id,
              }
            : undefined,
        assignees: {
          groupIds: assignees.groups.map((g) => g.id) || [],
          userIds: assignees.users.map((u) => u.id) || [],
        },
        name: serviceName,
        categoryId: category.id,
        currencyCode: data.currencyCode,
        questions: convertUITicketQuestionToAddMeetingServiceQuestionInput(
          questions,
          data.currencyCode
        ),
      };

      // If the service id already exists, we can assume we are updating
      // an existing service rather than adding a new one
      if (
        serviceId &&
        getMeetingServiceByIdQuery?.data?.getMeetingServiceById.meetingService
          ?.versionId
      ) {
        await updateMeetingService({
          variables: {
            uInput: {
              meetingServiceId: serviceId,
              meetingServiceVersionId:
                getMeetingServiceByIdQuery.data.getMeetingServiceById
                  .meetingService.versionId,
              changes: {
                ...meetingServiceInput,
                availableInSpacesByBuildingChanges:
                  availableInSpacesByBuildingChanges.length === 0
                    ? undefined
                    : availableInSpacesByBuildingChanges,
              },
            },
          },
          onCompleted: (res) => {
            if (
              res.updateMeetingService.__typename ===
              'UpdateMeetingServiceSuccessResponse'
            ) {
              onSuccessfulSubmit('updated');
            }

            if (
              res.updateMeetingService.__typename ===
              'UpdateMeetingServiceErrorResponse'
            ) {
              if (res.updateMeetingService.reason === 'CONFLICT') {
                setHasUpdateServiceConflict(true);
              } else {
                toast.error(t(`service_details.toasts.error`));
              }
            }
          },
          onError: () => {
            toast.error(t(`service_details.toasts.error`));
          },
        });
      } else {
        await addMeetingService({
          variables: {
            meetingServiceInput: {
              ...meetingServiceInput,
              /**
               * Convert the mapped location data back into an array of objects
               * Remove any 'undefined' spaces (means they were not touched/updated by the user)
               */
              availableInSpacesByBuilding,
            },
          },
          onCompleted: (res) => {
            if (
              res.addMeetingService.__typename ===
              'AddMeetingServiceSuccessResponse'
            ) {
              onSuccessfulSubmit('added');
            }
          },
          onError: () => {
            toast.error(t(`service_details.toasts.error`));
          },
        });
      }
    }
  };

  const refetchMeetingService = useCallback(() => {
    return getMeetingServiceByIdQuery?.refetch()
      ? getMeetingServiceByIdQuery.refetch()
      : Promise.resolve();
  }, [getMeetingServiceByIdQuery]);

  return {
    methods,
    onSubmit,
    refetchMeetingService,
    savingMeetingService,
    validatedData,
    hasUpdateServiceConflict,
    setHasUpdateServiceConflict,
    error: getMeetingServiceByIdQuery?.error,
    loading:
      savingMeetingService ||
      getMeetingServiceByIdQuery?.loading ||
      updatingMeetingService,
  };
};
