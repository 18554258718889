import styled from '@emotion/styled';
import { Colors, TextLink } from '@robinpowered/design-system';
import { useSelectedBuildingTableContext } from '../contexts';
import { useTranslation } from 'react-i18next';

export const DeselectBanner = () => {
  const { t } = useTranslation('ServiceSection');
  const { selectedSpaceIds, allSpaces, handleDeselectAllSpaces } =
    useSelectedBuildingTableContext();

  return (
    <Wrapper>
      {t(`location.table.deselect_banner.label`, {
        selected: selectedSpaceIds.size,
        total: allSpaces?.length || 0,
      })}
      <TextLink
        label={t(`location.table.deselect_banner.link.label`)}
        onClick={handleDeselectAllSpaces}
        variant="inverted"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  width: 100%;
  background: ${Colors.Blue110};
  color: ${Colors.White0};
  margin-bottom: 16px;
`;
