import { useEffect, useRef } from 'react';

export const useScrollTo = () => {
  const scrollToQuestionErrorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToQuestionErrorRef.current) {
      scrollToQuestionErrorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [scrollToQuestionErrorRef]);

  return {
    scrollToQuestionErrorRef,
  };
};
