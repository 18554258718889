import { Body, ButtonV4, Colors, Heading } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { FieldError } from 'components/ServiceForm/components/Fields';
import { useTranslation } from 'react-i18next';

export const EmptyMessage = ({
  title,
  description,
  errorMessage,
  addEmptyContext,
}: {
  title: string;
  description: string;
  errorMessage?: string;
  addEmptyContext?: () => void;
}): JSX.Element => {
  const { t } = useTranslation('MeetingServicePage');
  return (
    <>
      <EmptyMessageContainer>
        <Heading.Regular>{title}</Heading.Regular>
        <Body.Small>{description}</Body.Small>

        {addEmptyContext && (
          <AddEmptyContextButtonContainer onClick={addEmptyContext}>
            <ButtonV4
              variant="secondary"
              label={t('emptyService.button')}
              onClick={addEmptyContext}
            />
          </AddEmptyContextButtonContainer>
        )}
      </EmptyMessageContainer>

      {errorMessage && <FieldError message={errorMessage} />}
    </>
  );
};

const EmptyMessageContainer = styled.div`
  width: 100%;
  height: 254px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.Gray10};
  margin-top: 16px;
`;

const AddEmptyContextButtonContainer = styled.div`
  margin-top: 16px;
`;
