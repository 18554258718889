import { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

const createStorageKey = (key: string, orgSlug: string) =>
  `robin:tickets:${orgSlug}:${key}`;

// ts 4.7+ will allow referencing lower-order function return type automatically
// `ReturnType<typeof useLocalStorage<T>>`
type UseLocalStorageReturnType<T> = [
  T | undefined,
  Dispatch<SetStateAction<T | undefined>>,
  () => void
];

export function useTenantLocalStorage<T>(
  key: string,
  initialValue?: T
): UseLocalStorageReturnType<T> {
  const { orgSlug } = useParams<{ orgSlug: string }>();

  return useLocalStorage<T>(
    createStorageKey(key, orgSlug as string),
    initialValue
  );
}
