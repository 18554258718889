import styled from '@emotion/styled';
import { Avatar, Body, Colors } from '@robinpowered/design-system';
import { useServiceRequestContext } from 'components/ServiceRequest/contexts/ServiceRequestContext';
import { Row } from './components/Row';
import { CalendarSolid, GroupSolid, MinorPinSolid } from '@robinpowered/icons';
import { useTranslation } from 'react-i18next';
import { LoadingDetailCard } from './components/LoadingDetailCard';
import { Assignees } from './components/Assignees/Assignees';

export const DetailCard = () => {
  const { t } = useTranslation('MeetingServiceRequest');
  const {
    requestedBy,
    dueDate,
    locationName,
    numOfAttendees,
    loading,
    loadingLocation,
    selectedServiceWithoutQuestions,
  } = useServiceRequestContext();

  return (
    <Wrapper>
      {loading || loadingLocation ? (
        [
          t(`detail_card.requested`),
          t(`detail_card.location`),
          t(`detail_card.due`),
          t(`detail_card.attendees`),
        ].map((title, index) => {
          return <LoadingDetailCard title={title} key={index} />;
        })
      ) : (
        <>
          <Row title={t(`detail_card.requested`)}>
            <Avatar
              src={requestedBy?.avatar || ''}
              size={24}
              name={requestedBy?.name || ''}
            />
            <Body.Small>{requestedBy?.name}</Body.Small>
          </Row>
          <Row title={t(`detail_card.location`)}>
            <MinorPinSolid size={14} color={Colors.Gray60} />
            <Body.Small>{locationName}</Body.Small>
          </Row>
          {selectedServiceWithoutQuestions && (
            <Row title={'Assignees'}>
              <Assignees />
            </Row>
          )}

          <Row title={t(`detail_card.due`)}>
            <CalendarSolid size={14} color={Colors.Gray60} />
            <Body.Small>{dueDate.toString()}</Body.Small>
          </Row>
          <Row title={t(`detail_card.attendees`)}>
            <GroupSolid size={14} color={Colors.Gray60} />
            <Body.Small>{numOfAttendees}</Body.Small>
          </Row>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${Colors.Gray5};
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;
