import { useTranslation } from 'react-i18next';
import { Body, TextInput } from '@robinpowered/design-system';
import styled from '@emotion/styled';

type Props = {
  value: string;
  onChange: (value: string) => void;
  error: string | undefined;
  label?: string;
};

export const Key = ({ value, onChange, error, label }: Props) => {
  const { t } = useTranslation('ServiceSection');

  return (
    <InputWrapper>
      <TextInput
        name={t(`service_details.inputs.category.key.label`)}
        label={t(`service_details.inputs.category.key.label`)}
        placeholder={t(`service_details.inputs.category.key.placeholder`)}
        value={value}
        onChange={onChange}
        error={error}
      />
      <Body.Small>{label}</Body.Small>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
