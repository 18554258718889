import React, { MouseEvent, useCallback, useRef } from 'react';
import Close from '@robinpowered/icons/Close';
import styled from '@emotion/styled';
import {
  Colors,
  Heading,
  UtilityButton,
  useFocusTrap,
} from '@robinpowered/design-system';
import { ImageUploader } from './ImageUploader';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'components/ServiceForm/components';
import { Sentry } from 'lib/sentry';

const MAX_IMAGE_URL_CHAR_SIZE = 4096;

export type ImageUploadModalProps = {
  title: string;
  onCancel: () => void;
  getUploadedImage: (imageUrl: string) => void;
};

export const ImageUploadModal = ({
  title,
  onCancel,
  getUploadedImage,
}: ImageUploadModalProps) => {
  const backgroundRef = useRef(null);
  const { t } = useTranslation('QuestionSection');
  /* Try to trap the focus in the modal, bad things can happen otherwise */
  const focus = useFocusTrap<HTMLDivElement>();
  const [hasImageError, setHasImageError] = React.useState(false);

  const onBackgroundOverlayClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (onCancel && event.target === backgroundRef?.current) {
        onCancel();
      }
    },
    [onCancel]
  );

  const handleUploadedImage = (imageUrl: string) => {
    if (imageUrl.length > MAX_IMAGE_URL_CHAR_SIZE) {
      Sentry.captureMessage(`Unexpected image url size: ${imageUrl.length}`);
      setHasImageError(true);
      return;
    } else setHasImageError(false);
    getUploadedImage(imageUrl);
    onCancel();
  };

  return (
    <Background onClick={onBackgroundOverlayClick} ref={backgroundRef}>
      <Container role="dialog" aria-labelledby="modalTitle" ref={focus}>
        <ModalHeader>
          <TitleContainer>
            <Heading.Large id="modalTitle">{title}</Heading.Large>
          </TitleContainer>

          <UtilityButton
            icon={() => <Close size={24} style={{ cursor: 'pointer' }} />}
            onClick={onCancel}
            autoFocus={true}
          />
        </ModalHeader>

        <UploadContainer>
          <ImageUploader onComplete={handleUploadedImage} />
        </UploadContainer>
      </Container>
      {hasImageError && <FieldError message={t('errors.image_char_size')} />}
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(42, 42, 42, 0.5);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 536px;
  padding: 24px 24px 16px 24px;
  background: ${Colors.White0};
  border-radius: 4px;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 24px;
  border-radius: 4px;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
