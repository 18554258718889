import styled from '@emotion/styled';
import {
  Body,
  Colors,
  Heading,
  SpinnerLoader,
} from '@robinpowered/design-system';
import { ArrowUp, WarningSolid } from '@robinpowered/icons';
import React, { DragEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'config';
import { useAccessToken } from 'hooks';

const MAX_UPLOAD_SIZE = 5000000; /* Bytes */

export type ImageUploadProps = {
  /** Hook into the cancellation button action. */
  onComplete: (imageUrl: string) => void;
};

export const ImageUploader = ({ onComplete }: ImageUploadProps) => {
  const [dragging, setDragging] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const accessToken = useAccessToken();
  const { t } = useTranslation('QuestionsSection');

  const handleDragOut = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (!checkIsValidImageFile(e.dataTransfer.files)) {
      setErrorMessage(t('image_upload.unsupported_file'));
      return;
    }
    setErrorMessage(null);
    const image = e.dataTransfer.files[0];
    uploadImage(image);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (!checkIsValidImageFile(e.target.files)) {
        setErrorMessage(t('image_upload.unsupported_file'));
        return;
      }
      uploadImage(e.target.files[0]);
    }
  };

  /* We don't want
   *  Multiple Files
   *  non png/jpeg
   *  a file larger than 5mb
   */
  const checkIsValidImageFile = (file: FileList) => {
    return (
      file.length === 1 &&
      (file[0].type === 'image/jpeg' || file[0].type === 'image/png') &&
      file[0].size <= MAX_UPLOAD_SIZE
    );
  };

  const openFileDialog = () => {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput?.click();
  };

  const uploadImage = async (file: File): Promise<void> => {
    const formData = new FormData();
    formData.append('image', file);
    const url = `${config.imageServerUrl}/upload`;
    setUploadingImage(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: file,
        headers: {
          'Content-Type': file.type,
          Authorization: `Access-Token ${accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        onComplete(data.data.small.url);
      } else {
        setErrorMessage(t('image_upload.something_went_wrong'));
      }
      setUploadingImage(false);
    } catch (error) {
      setErrorMessage(t('image_upload.something_went_wrong'));
      setUploadingImage(false);
    }
  };

  return uploadingImage ? (
    <Container style={{ gap: 10 }}>
      <SpinnerLoader size={16} />
      <Body.Small>{t('image_upload.uploading')}</Body.Small>
    </Container>
  ) : (
    <>
      <Container
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragOut}
        onClick={openFileDialog}
        onKeyPress={(e) => {
          if (e.key === 'Enter') openFileDialog();
        }}
        style={errorMessage ? { border: `1px dashed ${Colors.Red100}` } : {}}
        tabIndex={0}
      >
        <input
          type="file"
          onChange={handleFileChange}
          accept={'image/png' || 'image/jpeg'}
          multiple
          style={{ display: 'none' }}
          id="fileInput"
          aria-label={t('image_upload.upload_an_image')}
        />
        <ArrowUp
          size={24}
          style={{
            borderRadius: '100px',
            border: `1px solid ${Colors.Gray60}`,
          }}
        />
        <Heading.Small style={{ cursor: 'pointer' }}>
          {dragging ? (
            <p>{t('image_upload.dropImageHere')}</p>
          ) : (
            <p>{t('image_upload.click_to_add_file')}</p>
          )}
        </Heading.Small>
        <DimensionsText>
          <Body.Small>{t('image_upload.optimal')}</Body.Small>
          <Body.Small>{t('image_upload.size_limit')}</Body.Small>
        </DimensionsText>
      </Container>
      {errorMessage && (
        <ErrorContainer>
          <WarningSolid size={16} color={Colors.Red100} />
          <ErrorText>{errorMessage}</ErrorText>
        </ErrorContainer>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  min-height: 250px;
  border-radius: 4px;
  padding: 24px;
  border: 1px dashed ${Colors.Gray30};
  cursor: pointer;
`;

const DimensionsText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const ErrorText = styled(Body.Small)`
  color: ${Colors.Red100};
`;
