import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import { Option } from '.';
import {
  UITicketQuestionExpectingChoices,
  UITicketQuestionOptionInput,
} from 'types';
import { useCallback } from 'react';
import { useQuestionBuilderContext } from '../contexts/QuestionBuilderContext';

type OptionsListProps = {
  options: UITicketQuestionOptionInput[];
};

export const OptionsList = ({ options }: OptionsListProps): JSX.Element => {
  const { onUpdateOptions, question } = useQuestionBuilderContext();

  // Used for the dnd kit sorting api
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    (e: DragEndEvent) => {
      const { active, over } = e;
      if (active.id && over !== null && active.id !== over.id) {
        const oldIndex = options
          .map((option) => option.id)
          .indexOf(active.id as string);
        const newIndex = options
          .map((option) => option.id)
          .indexOf(over.id as string);

        const newOptions = arrayMove(options, oldIndex, newIndex);
        onUpdateOptions(newOptions);
      }
    },
    [onUpdateOptions, options]
  );

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={options} strategy={verticalListSortingStrategy}>
          <ul>
            {options.map((option, i) => {
              return (
                <Option
                  key={option.id}
                  option={option}
                  index={i}
                  showDelete={
                    (question as UITicketQuestionExpectingChoices)
                      .chooseOnlyOneOption === false
                      ? options.length > 2
                      : options.length > 1
                  }
                />
              );
            })}
          </ul>
        </SortableContext>
      </DndContext>
    </>
  );
};
