import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GetBuildingsWhereUserCanManageMeetingServicesForFormQuery,
  GetLocationsByOrgIdQuery,
} from 'generated';
import { useTenantLocalStorage } from './useTenantLocalStorage';
import { useTranslation } from 'react-i18next';

export const SELECTED_LOCATIONS = 'selected-locations';

export type useManageMeetingServicesAPI = {
  organizationLocation: { id: string; name: string }[];
  locationsUserCanManage: ({
    __typename?: 'Location' | undefined;
    id: string;
    name: string;
    totalSpaces: number;
  } | null)[];
  handleSelectBuilding: (value: string[]) => void;
  selectedLocations: string[];
  handleSelect: (value: string) => void;
  handleDeselectAll: () => void;
};

export const useManageMeetingServices = (
  buildingData:
    | GetBuildingsWhereUserCanManageMeetingServicesForFormQuery
    | undefined,
  orgData: GetLocationsByOrgIdQuery | undefined
): useManageMeetingServicesAPI => {
  const { t } = useTranslation('MeetingServicePage');
  const organizationLocation = useMemo(() => {
    return orgData?.getOrganizationById?.locations || [];
  }, [orgData?.getOrganizationById?.locations]);
  const locationsUserCanManage = useMemo(() => {
    return (
      buildingData?.listBuildingsWhereUserCanManageMeetingServices?.buildings ||
      []
    );
  }, [buildingData]);

  const [persistedSelectedLocations, setPersistedSelectedLocations] =
    useTenantLocalStorage<string[]>(SELECTED_LOCATIONS, []);

  const [selectedLocations, setSelectedLocations] = useState<string[]>(() => {
    return persistedSelectedLocations
      ? persistedSelectedLocations
      : locationsUserCanManage.map((location) => {
          return location?.id || '';
        });
  });

  const updateSelectedLocations = useCallback(
    (values: string[]) => {
      setPersistedSelectedLocations(values);
      setSelectedLocations(values);
    },
    [setSelectedLocations, setPersistedSelectedLocations]
  );

  const handleSelectBuilding = useCallback(
    (values: string[]) => {
      updateSelectedLocations(values);
    },
    [updateSelectedLocations]
  );

  const handleDeselectAll = useCallback(() => {
    updateSelectedLocations([]);
  }, [updateSelectedLocations]);

  const handleSelect = useCallback(
    (value) => {
      const allLocationsSelected =
        selectedLocations.length === organizationLocation.length;
      /* Select all spaces if we click the select all option */
      if (value === t('selectLocation.select_all')) {
        if (allLocationsSelected) {
          handleDeselectAll();
        } else {
          handleSelectBuilding(organizationLocation.map((option) => option.id));
        }
      }
    },
    [
      handleDeselectAll,
      handleSelectBuilding,
      organizationLocation,
      selectedLocations,
      t,
    ]
  );

  const setDefaultBuildings = useCallback(() => {
    setSelectedLocations(
      locationsUserCanManage.map((location) => {
        return location?.id || '';
      })
    );
  }, [locationsUserCanManage]);

  const initialPersistedSelectedLocations =
    useTenantLocalStorage<string[]>(SELECTED_LOCATIONS)[0];

  useEffect(() => {
    if (initialPersistedSelectedLocations?.length === 0) {
      setDefaultBuildings();
    }
  }, [setDefaultBuildings, initialPersistedSelectedLocations]);

  return {
    organizationLocation,
    locationsUserCanManage,
    handleSelectBuilding,
    selectedLocations,
    handleSelect,
    handleDeselectAll,
  };
};
