import React, { ReactNode } from 'react';
import {
  Body,
  Colors,
  Heading,
  PaginatedList,
  Table as RobinTable,
} from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTablePagination } from './hooks';

export type ColumnType = {
  Header: string | JSX.Element;
  accessor: string;
  disableSortBy: boolean;
};

type TableCell = {
  component: ReactNode;
  isSelected?: boolean;
  /** Used for sorting by table header */
  name?: string | number;
};
export type TableDataType<T extends string | number | symbol> = Record<
  T,
  TableCell
>;

export type TableProps<P extends string | number | symbol> = {
  data: TableDataType<P>[];
  columns: Array<ColumnType>;
};

export type ServiceTableColumnNames =
  | 'select'
  | 'space'
  | 'capacity'
  | 'type'
  | 'floor'
  | 'view_map';

export const Table = ({
  data,
  columns,
}: TableProps<ServiceTableColumnNames>): JSX.Element => {
  const {
    paginatedData,
    activePage,
    handleClickPrevious,
    handleClickNext,
    handlePageNumberClick,
    itemsPerPage,
  } = useTablePagination(data);

  return (
    <>
      <RobinTable>
        <thead>
          <TableHeaderContainer>
            {columns.map(({ Header }, index) => (
              <RobinTable.Th key={index}>{Header}</RobinTable.Th>
            ))}
          </TableHeaderContainer>
        </thead>
        <tbody>
          {paginatedData.length === 0 ? (
            <EmptyTableRow colspan={columns.length} />
          ) : (
            paginatedData.map(({ ...data }, index, arr) => (
              <TableRow key={index} isSelected={!!data.select.isSelected}>
                {Object.values(data).map(({ component }, i, values) => (
                  <TableCellWrapper key={i}>{component}</TableCellWrapper>
                ))}
              </TableRow>
            ))
          )}
        </tbody>
      </RobinTable>

      {paginatedData.length !== 0 && (
        <PaginatedList
          containerStyle={{ marginTop: '16px' }}
          prevLabel={''}
          nextLabel={''}
          onPrevClick={handleClickPrevious}
          onNextClick={handleClickNext}
          onPageClick={handlePageNumberClick}
          numRecords={data.length}
          numRecordsPerPage={itemsPerPage}
          activePage={activePage}
        />
      )}
    </>
  );
};

const TableHeaderContainer = styled(RobinTable.Tr)`
  width: 100%;

  > th {
    background-color: ${Colors.Gray20};
  }
`;

const TableCellWrapper = styled(RobinTable.Td)`
  vertical-align: middle;
  max-width: 200px;
`;

const TableRow = styled(RobinTable.Tr)<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) =>
    isSelected ? Colors.Blue10 : Colors.White0};
`;

const EmptyTableRow = ({ colspan }: { colspan: number }) => {
  const { t } = useTranslation('ServiceSection');
  return (
    <RobinTable.Tr>
      <RobinTable.Td colSpan={colspan} height={'200px'}>
        <EmptyTableWrapper>
          <Heading.Regular>{t(`location.table.empty.header`)}</Heading.Regular>
          <Body.Small>{t(`location.table.empty.subheader`)}</Body.Small>
        </EmptyTableWrapper>
      </RobinTable.Td>
    </RobinTable.Tr>
  );
};

const EmptyTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
