import { useTranslation } from 'react-i18next';
import { Body, RichTextInput } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import Graphemer from 'graphemer';

type DescriptionProps = {
  name: string;
  description: string;
  minHeight: string;
  label?: string;
  hasTip?: boolean;
  onUpdate?: ((markdownString: string) => void) | undefined;
};

export const Description = ({
  name,
  label,
  minHeight,
  description,
  hasTip,
  onUpdate,
}: DescriptionProps) => {
  const { t } = useTranslation('ServiceSection');
  const [descriptionError, setDescriptionError] = useState(false);

  useEffect(() => {
    const settingDescriptionError = setTimeout(() => {
      setDescriptionError(
        !!(description && new Graphemer().countGraphemes(description) > 2000)
      );
    }, 500);

    return () => {
      clearTimeout(settingDescriptionError);
    };
  }, [description]);

  return (
    <div>
      {/* @TODO will need to update the design system by adding a string limit */}
      <RichTextInput
        placeholder={name}
        name={name}
        label={label}
        onUpdate={onUpdate}
        content={description}
        error={
          descriptionError
            ? t(`service_details.inputs.description.error`)
            : undefined
        }
        minContentHeight={minHeight}
      />
      {hasTip && <StyledText>{t(`service_details.tip`)}</StyledText>}
    </div>
  );
};

const StyledText = styled(Body.Small)`
  margin-top: 6px;
  display: block;
`;
