import { useCallback, useMemo, useState } from 'react';

export const useSelectTableRow = <T extends { id: string }>(
  // i.e. data reflecting the users filtered table down to an explicit new set which changes
  // the context of which rows are currently selected
  dataWithIds: T[],
  defaultRows: Set<string>
) => {
  const [selectedRowIds, setSelectedRowIds] = useState<Set<string>>(
    defaultRows ? defaultRows : new Set()
  );

  const areAllDataItemsSelected = useMemo(
    () =>
      selectedRowIds.size > 0 &&
      dataWithIds.length > 0 &&
      selectedRowIds.size >= dataWithIds.length &&
      !dataWithIds.some((item) => !selectedRowIds.has(item.id)),
    [selectedRowIds, dataWithIds]
  );

  const areSomeButNotAllRowsSelected = useMemo(() => {
    return !areAllDataItemsSelected && [...selectedRowIds].length > 0;
  }, [areAllDataItemsSelected, selectedRowIds]);

  const handleClickColumnHeaderCheckbox = useCallback(() => {
    setSelectedRowIds((prev) => {
      return areAllDataItemsSelected
        ? new Set([
            ...[...prev].filter(
              (rowId) => !dataWithIds.find((item) => item.id === rowId)
            ),
          ])
        : new Set([...[...prev, ...dataWithIds.map((item) => item.id)]]);
    });
  }, [dataWithIds, areAllDataItemsSelected]);

  return {
    selectedRowIds,
    setSelectedRowIds,
    handleClickColumnHeaderCheckbox,
    areSomeButNotAllRowsSelected,
    areAllDataItemsSelected,
  };
};
