import styled from '@emotion/styled';
import {
  Heading01 as Heading01Base,
  Heading02 as Heading02Base,
  Heading03 as Heading03Base,
  Heading04 as Heading04Base,
  Heading05 as Heading05Base,
  Heading06 as Heading06Base,
  Body05 as Body05Base,
  Colors,
} from '@robinpowered/design-system';

export const Heading01 = styled(Heading01Base)``;
export const Heading02 = styled(Heading02Base)``;
export const Heading03 = styled(Heading03Base)``;
export const Heading04 = styled(Heading04Base)``;
export const Heading05 = styled(Heading05Base)``;
export const Heading06 = styled(Heading06Base)``;
export const Body05 = styled(Body05Base)``;
export const Del = styled.del`
  vertical-align: baseline;
`;

export const Paragraph = styled.p`
  color: ${Colors.Gray100};
  line-height: normal;
  overflow-wrap: break-word;
`;

export const ListItem = styled.li`
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const UnorderedList = styled.ul`
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 24px;
  list-style: disc;
`;

export const Link = styled.a`
  color: ${Colors.Gray100};
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: ${Colors.Gray30};

  &:focus,
  &:focus-visible {
    color: ${Colors.Gray100};
    text-decoration: none;
    outline: 4px solid ${Colors.Blue100};
    outline-offset: 4px;
  }

  &:hover {
    color: ${Colors.Blue100};
    text-decoration-color: ${Colors.Blue100};
  }
`;
