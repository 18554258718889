import { Avatar, Body } from '@robinpowered/design-system';
import styled from '@emotion/styled';

export const Assignee = ({
  name,
  avatar,
  label,
}: {
  name: string;
  avatar: string;
  label: string;
}) => {
  return (
    <AssigneeWrapper>
      <Avatar src={avatar} name={name} size={24} />
      <Body.Small>{label}</Body.Small>
    </AssigneeWrapper>
  );
};

const AssigneeWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
`;
