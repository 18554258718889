import { useTranslation } from 'react-i18next';
import { TextInput } from '@robinpowered/design-system';

type Props = {
  value: string;
  onChange: (value: string) => void;
  error: string | undefined;
};

export const Name = ({ value, onChange, error }: Props) => {
  const { t } = useTranslation('ServiceSection');

  return (
    <TextInput
      name={t(`service_details.inputs.category.name.label`)}
      label={t(`service_details.inputs.category.name.label`)}
      value={value}
      placeholder={t(`service_details.inputs.category.name.placeholder`)}
      onChange={onChange}
      error={error}
    />
  );
};
