import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Heading } from '@robinpowered/design-system';
import { ChevronDownOutline } from '@robinpowered/icons';
import { NavLink, useParams } from 'react-router-dom';

export const SidebarAccordion = ({
  items,
  title,
}: {
  items: string[];
  title: string;
}) => {
  const { orgSlug } = useParams();
  const [showItems, setShowItems] = useState(true);

  return (
    <AccordionWrapper>
      <AccordionTitle
        onClick={() => setShowItems((prev) => !prev)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') setShowItems((prev) => !prev);
        }}
        tabIndex={0}
      >
        <Heading.Regular>{title}</Heading.Regular>
        <ChevronDownOutline
          size={16}
          style={{
            transform: showItems ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.25s ease',
          }}
        />
      </AccordionTitle>
      {showItems &&
        items.map((item, index) => {
          const to = `/${orgSlug}${`/tickets/${item
            .toLowerCase()
            .replace(' ', '-')}`}`;
          return (
            <AccordionItem active={false} key={index} tabIndex={0}>
              <NavLink to={to}>{item}</NavLink>
            </AccordionItem>
          );
        })}
    </AccordionWrapper>
  );
};

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

const AccordionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
`;

const AccordionItem = styled.div<{ active: boolean }>``;
