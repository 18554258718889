import styled from '@emotion/styled';
import { Checkbox } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';

type RequiredToggleProps = {
  onToggle: () => void;
  checked: boolean;
};

export const RequiredToggle = ({
  checked,
  onToggle,
}: RequiredToggleProps): JSX.Element => {
  const { t } = useTranslation('QuestionsSection');

  return (
    <ToggleWrapper>
      <Checkbox checked={checked} onChange={onToggle}>
        {t('required.label')}
      </Checkbox>
    </ToggleWrapper>
  );
};

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: auto;
`;
