import { ButtonV4 } from '@robinpowered/design-system';
import { theme } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { EditAlt, TrashOutline } from '@robinpowered/icons';
import { useTranslation } from 'react-i18next';
import { useEditServiceButton } from './hooks/useEditServiceButton';

// @TODO: We can remove the undefines once these are implemented
type Props = {
  serviceId: string;
  canDelete?: boolean;
  canUpdate?: boolean;
};

export const ServiceActionButtons = ({
  serviceId,
  canDelete,
  canUpdate,
}: Props) => {
  const { t } = useTranslation('MeetingServicePage');
  const { onEdit, onDelete } = useEditServiceButton();
  const { useToken } = theme;
  const { token } = useToken();
  return (
    <Container>
      <span title={t(`table.rows.edit.edit`)}>
        <TransparentButton
          icon={() => <EditAlt color={token.colorText} />}
          onClick={() => onEdit(serviceId)}
          disabled={!canUpdate}
        />
      </span>
      {/* <span title={t(`table.rows.edit.duplicate`)}>
        <TransparentButton
          icon={() => <CopyOutline color={Colors.Gray60} />}
          onClick={() => {
            if (onDuplicate) {
              onDuplicate();
            }
          }}
        />
      </span>   */}
      <span title={t(`table.rows.edit.delete`)}>
        <TransparentButton
          icon={() => <TrashOutline color={token.colorError} />}
          onClick={() => {
            if (onDelete) {
              onDelete(serviceId);
            }
          }}
          disabled={!canDelete}
        />
      </span>
    </Container>
  );
};

const TransparentButton = styled(ButtonV4)`
  background: transparent;
  margin: auto;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;
