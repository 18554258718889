import {
  BrownFontStack,
  CheckboxV4,
  Colors,
  Heading,
  TextLink,
} from '@robinpowered/design-system';
import { useAuthContext } from 'contexts';
import { GetBuildingByIdForServiceBuilderQuery } from 'generated';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceTableColumnNames, TableDataType } from '../components';
import styled from '@emotion/styled';
import {
  AvailableInSpacesByBuilding,
  MeetingServiceType,
} from 'components/ServiceForm/ServiceFormContainer';
import { useFormContext } from 'react-hook-form';
import { useSelectTableRow } from 'hooks/useSelectTableRow';

type SortByTypes = 'space' | 'capacity' | 'type' | 'floor';

export const useManageBuildingServiceTable = (
  filteredData: GetBuildingByIdForServiceBuilderQuery | undefined,
  buildingId: string,
  selectedBuildings: AvailableInSpacesByBuilding
) => {
  const { t } = useTranslation('ServiceSection');
  const { getValues, setValue } = useFormContext<MeetingServiceType>();
  const { currentOrg } = useAuthContext();

  const [sortTableDataBy, setSortTableDataBy] = useState<{
    type: SortByTypes;
    direction: 'asc' | 'desc';
  } | null>(null);

  const allFilteredSpaces = useMemo(
    () => filteredData?.getLocationById?.spaces || [],
    [filteredData?.getLocationById?.spaces]
  );

  const initializeSpaces = () => {
    const formContextSelectedBuildings = getValues(
      'availableInSpacesByBuilding'
    );
    // Allows the form to persist user selections when tabbing between
    // different views on the form
    const currentSelectedBuilding =
      formContextSelectedBuildings.get(buildingId);

    return currentSelectedBuilding
      ? new Set(currentSelectedBuilding)
      : new Set<string>();
  };

  const {
    setSelectedRowIds,
    areAllDataItemsSelected,
    areSomeButNotAllRowsSelected,
    handleClickColumnHeaderCheckbox,
  } = useSelectTableRow(allFilteredSpaces, initializeSpaces());

  const selectedRowIds = selectedBuildings.get(buildingId);

  const handleClickHeader = useCallback((type: SortByTypes) => {
    setSortTableDataBy((prev) => {
      return prev?.direction === 'desc'
        ? null
        : {
            type: type,
            direction:
              prev?.direction === 'asc' && prev.type === type ? 'desc' : 'asc',
          };
    });
  }, []);
  useEffect(() => {
    if (selectedBuildings) {
      setSelectedRowIds(new Set(selectedBuildings.get(buildingId)));
    }
  }, [selectedBuildings, setSelectedRowIds, buildingId]);

  const buildLinkToSpace = useCallback(
    (spaceId: string, levelId: string | undefined) => {
      const locationId = filteredData?.getLocationById?.id;

      // If the space does not have a level, then link to the space detail page
      const params = levelId
        ? `office?location=${locationId}&levels=${levelId}&space=${spaceId}`
        : `locations/${locationId}/spaces/${spaceId}`;

      return `/${currentOrg?.slug}/${params}`;
    },
    [currentOrg?.slug, filteredData?.getLocationById?.id]
  );

  const selectSpace = useCallback(
    (spaceId: string) => {
      const selectedBuildingsCopy = structuredClone(selectedBuildings);
      const selectedSpaces = selectedBuildingsCopy.get(buildingId);
      selectedSpaces?.has(spaceId)
        ? selectedSpaces.delete(spaceId)
        : selectedSpaces?.add(spaceId);

      setValue('availableInSpacesByBuilding', selectedBuildingsCopy);
    },
    [selectedBuildings, buildingId, setValue]
  );

  const deselectAllSpaces = useCallback(() => {
    const buildingClone = structuredClone(
      getValues('availableInSpacesByBuilding')
    );

    setValue(
      'availableInSpacesByBuilding',
      buildingClone.set(buildingId, new Set())
    );
  }, [buildingId, getValues, setValue]);

  /* Need to manage the state differently here because updating how the method
  handleClickColumnHeaderCheckbox works would affect the MeetingService Table component. */
  const selectAllSpaces = useCallback(() => {
    handleClickColumnHeaderCheckbox();
    const selectedBuilding = new Map(selectedBuildings);
    const selectedSpaces = new Set(selectedBuilding.get(buildingId));
    if (selectedSpaces) {
      if (areAllDataItemsSelected) {
        selectedSpaces.clear();
      } else {
        allFilteredSpaces.forEach((space) => selectedSpaces.add(space.id));
      }
    }
    selectedBuilding.set(buildingId, selectedSpaces);
    setValue('availableInSpacesByBuilding', selectedBuilding);
  }, [
    setValue,
    selectedBuildings,
    buildingId,
    allFilteredSpaces,
    handleClickColumnHeaderCheckbox,
    areAllDataItemsSelected,
  ]);

  const columns = [
    {
      Header: (
        <div
          style={{
            backgroundColor: Colors.White0,
            width: '20px',
            borderRadius: '2px',
          }}
        >
          <CheckboxV4
            checked={areAllDataItemsSelected}
            indeterminate={areSomeButNotAllRowsSelected}
            onChange={selectAllSpaces}
          />
        </div>
      ),
      accessor: 'select',
      disableSortBy: true,
    },
    {
      Header: (
        <FilterButton
          onClick={() => handleClickHeader('space')}
          name={t('location.table.headers.space')}
          type="button"
        >
          <Heading.Small>{t('location.table.headers.space')}</Heading.Small>
        </FilterButton>
      ),
      accessor: 'space',
      disableSortBy: true,
    },
    {
      Header: (
        <FilterButton
          onClick={() => handleClickHeader('capacity')}
          name={t('location.table.headers.capacity')}
          type="button"
        >
          <Heading.Small>{t('location.table.headers.capacity')}</Heading.Small>
        </FilterButton>
      ),
      accessor: 'capacity',
      disableSortBy: true,
    },
    {
      Header: (
        <FilterButton
          onClick={() => handleClickHeader('type')}
          name={t('location.table.headers.type')}
          type="button"
        >
          <Heading.Small>{t('location.table.headers.type')}</Heading.Small>
        </FilterButton>
      ),
      accessor: 'type',
      disableSortBy: true,
    },
    {
      Header: (
        <FilterButton
          onClick={() => handleClickHeader('floor')}
          name={t('location.table.headers.floor')}
          type="button"
        >
          <Heading.Small>{t('location.table.headers.floor')}</Heading.Small>
        </FilterButton>
      ),
      accessor: 'floor',
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: 'view_map',
      disableSortBy: true,
    },
  ];

  const tableData: TableDataType<ServiceTableColumnNames>[] = useMemo(() => {
    return (
      allFilteredSpaces?.map((space) => {
        const isRowSelected = selectedRowIds?.has(space.id);
        const level = filteredData?.getLocationById?.levels.find(
          (l) => l.id === space.levelId
        );
        return {
          select: {
            component: (
              <CheckboxV4
                checked={isRowSelected}
                onChange={() => {
                  selectSpace(space.id);
                }}
              />
            ),
            isSelected: isRowSelected,
          },
          space: {
            name: space.name.toLocaleLowerCase(),
            component: space.name,
          },
          capacity: {
            name: space?.capacity || 0,
            component: space.capacity,
          },
          type: {
            name: space?.type?.toLocaleLowerCase() || '',
            component: space?.type || '',
          },
          floor: {
            name: level?.name.toLocaleLowerCase() || '',
            component: level?.name || '',
          },
          view_map: {
            component: (
              <TextLink
                href={buildLinkToSpace(space.id, level?.id)}
                label={t(`location.table.rows.view`)}
                target="_blank"
              />
            ),
          },
        };
      }) || []
    );
  }, [
    allFilteredSpaces,
    buildLinkToSpace,
    filteredData?.getLocationById?.levels,
    selectedRowIds,
    t,
    selectSpace,
  ]);

  const sortAlgorithm = useCallback(
    (
      prev: TableDataType<ServiceTableColumnNames>,
      curr: TableDataType<ServiceTableColumnNames>,
      type: SortByTypes
    ) => {
      if (prev[type].name === curr[type].name) {
        return 0;
      }

      // Force all 'empty' values to the bottom of the sorting
      if (!prev[type].name) {
        return 1;
      }

      if (!curr[type].name) {
        return -1;
      }

      return (prev[type].name || '') > (curr[type].name || '')
        ? sortTableDataBy?.direction === 'asc'
          ? 1
          : -1
        : sortTableDataBy?.direction === 'asc'
        ? -1
        : 1;
    },
    [sortTableDataBy]
  );

  const sortedTableData = useMemo(() => {
    if (!sortTableDataBy) {
      return tableData;
    }
    return [...tableData].sort((prev, curr) => {
      return sortAlgorithm(prev, curr, sortTableDataBy.type);
    });
  }, [sortTableDataBy, tableData, sortAlgorithm]);

  return {
    tableData: sortedTableData,
    columns,
    deselectAllSpaces,
    selectedSpaceIds: selectedRowIds || new Set(),
  };
};

const FilterButton = styled.button`
  border: none;
  font-family: ${BrownFontStack};
  background: transparent;
  color: ${Colors.Gray100};
  cursor: pointer;
  padding: 4px 0;

  &:hover {
    opacity: 0.8;
  }
`;
