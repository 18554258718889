import { useTranslation } from 'react-i18next';

export const useValidateCategory = () => {
  const { t } = useTranslation('ServiceSection');

  const validateCategoryName = (
    name: string,
    categories: string[] | undefined
  ) => {
    if (name.length === 0) {
      return t(`service_details.inputs.category.name.validation.missing`);
    }

    if (
      categories &&
      categories
        .map((c) => c.toLocaleLowerCase())
        .includes(name.toLocaleLowerCase())
    ) {
      return t(`service_details.inputs.category.name.validation.duplicate`);
    }
    return false;
  };

  const validateCategoryKey = (key: string) => {
    if (key.length === 0) {
      return t(`service_details.inputs.category.key.validation.missing`);
    }

    if (!key.match(/^[A-Z][A-Z0-9_]+$/)) {
      return t(`service_details.inputs.category.key.validation.formatting`);
    }
  };
  return {
    validateCategoryKey,
    validateCategoryName,
  };
};
