import styled from '@emotion/styled';
import { Avatar, Body, ButtonV4, Colors } from '@robinpowered/design-system';
import {
  ChevronDownOutline,
  ChevronUpOutline,
  TrashOutline,
} from '@robinpowered/icons';
import { useState } from 'react';
import { keyframes } from '@emotion/react';
import { useSelectFacilitatorContext } from '../contexts';
import { FacilitatorGroup } from '../../usePersonGroupSearch';
import { useTranslation } from 'react-i18next';
import { buildThumbImage } from 'lib/images';
import { calcLogarithmicGrowth } from 'components/ServiceRequest/types/ServiceRequestTypes';

export const GroupFacilitators = (): JSX.Element => {
  const { groupFacilitator } = useSelectFacilitatorContext();

  return (
    <GroupWrapper>
      {groupFacilitator?.map((group, index) => {
        return <GroupFacilitator group={group} index={index} key={index} />;
      })}
    </GroupWrapper>
  );
};

const GroupFacilitator = ({
  group,
  index,
}: {
  group: FacilitatorGroup;
  index: number;
}) => {
  const { t } = useTranslation('ServiceSection');
  const [showList, setShowList] = useState(false);
  const toggleShowList = () => {
    if (groupHasMembers) setShowList((prev) => !prev);
  };
  const { removeGroupFacilitator } = useSelectFacilitatorContext();
  const groupHasMembers = group.members.length > 0;
  const groupTitle = `${group.name} (${group.members.length})`;
  return (
    <div className="groupFacilitator">
      <GroupHeader
        onClick={toggleShowList}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            toggleShowList();
          }
        }}
        tabIndex={0}
      >
        <Title>
          {groupHasMembers &&
            (showList ? (
              <ChevronUpOutline size={16} />
            ) : (
              <ChevronDownOutline size={16} />
            ))}
          <Body.Small>{groupTitle}</Body.Small>
        </Title>

        <span title={t('facilitator.remove')}>
          <DeleteButton
            icon={() => <TrashOutline size={16} color={Colors.Gray60} />}
            onClick={() => removeGroupFacilitator(index)}
            variant="tertiary"
          />
        </span>
      </GroupHeader>

      {showList && (
        <GroupFacilitatorList>
          {group.members.map((member, index) => {
            return (
              <Assignee index={index} key={index}>
                <Avatar
                  name={member.name || ''}
                  src={buildThumbImage(member.avatar)}
                />

                <Body.Small>{member.name || ''}</Body.Small>
              </Assignee>
            );
          })}
        </GroupFacilitatorList>
      )}
    </div>
  );
};

const GroupWrapper = styled.div`
  width: 100%;

  .groupFacilitator:not(:first-of-type) {
    border-top: 1px solid ${Colors.Gray20};
  }
`;

const GroupHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px;
  border-radius: 4px;

  &:hover {
    background-color: ${Colors.Gray5};
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const GroupFacilitatorList = styled.div`
  padding: 16px 16px 16px 48px;
  border-top: 1px solid ${Colors.Gray20};
  div:not(:first-of-type) {
    margin-top: 8px;
  }
`;

const fadeDown = keyframes`
  from {
    opacity: 0;
    top: -30px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
`;

const Assignee = styled.div<{ index: number }>`
  position: relative;
  top: -10px;
  display: flex;
  align-items: center;
  gap: 8px;
  animation: ${fadeDown};
  animation-duration: ${(props) =>
    /* We want to delay the animation on subsequent components */
    calcLogarithmicGrowth(0.2, 0.5, 0.8, props.index + 1)}s;
  animation-timing-function: cubic-bezier(0.65, -0.25, 0.38, 1.19);
  animation-fill-mode: forwards;
`;

const DeleteButton = styled(ButtonV4)`
  height: 30px;
  width: 30px;
`;
