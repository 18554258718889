import { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelectBuildingsContext } from '../../../contexts';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';

export const useManageSelectBuildingInput = () => {
  const { t } = useTranslation('ServiceSection');
  const { buildings: buildingsQuery, selectedBuildingIds } =
    useSelectBuildingsContext();
  const { setValue, getValues } = useFormContext<MeetingServiceType>();

  const buildings = useMemo(
    () =>
      buildingsQuery?.data?.listBuildingsWhereUserCanManageMeetingServices
        .buildings,
    [buildingsQuery]
  );

  const areAllBuildingsSelected = useMemo(() => {
    return buildings?.length === selectedBuildingIds.size;
  }, [buildings, selectedBuildingIds]);

  const deselectAllBuildings = useCallback(() => {
    setValue('availableInSpacesByBuilding', new Map());
  }, [setValue]);

  const handleSelectAll = useCallback(() => {
    if (buildings) {
      // If all the buildings are already selected, then deselect on the second click
      // otherwise, select all
      if (areAllBuildingsSelected) {
        deselectAllBuildings();
      } else {
        const newValues = buildings.reduce((acc, b) => {
          if (b?.id && !acc.has(b?.id)) {
            acc.set(b?.id, new Set<string>());
          }
          return acc;
        }, new Map(getValues('availableInSpacesByBuilding')));
        setValue('availableInSpacesByBuilding', newValues);
      }
    }
  }, [
    buildings,
    areAllBuildingsSelected,
    deselectAllBuildings,
    getValues,
    setValue,
  ]);

  const selectAllOption = useMemo(() => {
    return {
      label: t(`location.select_location.select_all`),
      value: uuidv4(),
      indeterminate: selectedBuildingIds.size > 0,
      onClick: handleSelectAll,
      checked: areAllBuildingsSelected,
    };
  }, [t, selectedBuildingIds, handleSelectAll, areAllBuildingsSelected]);

  const handleSelectBuilding = (option: { value: string; label: string }) => {
    const selectedBuildingsCopy = structuredClone(
      getValues('availableInSpacesByBuilding')
    );
    const isOptionCurrentlySelected = selectedBuildingsCopy.has(option.value);

    if (isOptionCurrentlySelected) {
      selectedBuildingsCopy.delete(option.value);
    } else {
      selectedBuildingsCopy.set(option.value, new Set<string>());
    }

    setValue('availableInSpacesByBuilding', selectedBuildingsCopy);
  };

  const options = useMemo(() => {
    return (
      buildings?.map((building) => {
        return {
          label: building?.name || '',
          value: building?.id || '',
        };
      }) || []
    );
  }, [buildings]);

  const value = useMemo(() => {
    return [...selectedBuildingIds].map((id) => {
      const selectBuilding = buildings?.find((b) => b?.id === id);
      return id === selectAllOption.value
        ? selectAllOption
        : {
            label: selectBuilding?.name || '',
            value: selectBuilding?.id || '',
          };
    });
  }, [buildings, selectAllOption, selectedBuildingIds]);

  const inputText = useMemo(
    () =>
      selectedBuildingIds.size === 0
        ? undefined
        : t(`location.select_location.label`, {
            count: selectedBuildingIds.size,
          }),
    [selectedBuildingIds, t]
  );

  return {
    options,
    value,
    handleSelectBuilding,
    selectAllOption,
    inputText,
  };
};
