import { useQuestionContext } from 'components/ServiceRequest/contexts/QuestionContext';
import { useServiceRequestContext } from 'components/ServiceRequest/contexts/ServiceRequestContext';
import Graphemer from 'graphemer';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useTextAnswer = (type: 'short' | 'long') => {
  const { t } = useTranslation('MeetingServiceRequest');
  const MAX_ANSWER_LENGTH = type === 'short' ? 200 : 2000;
  const { id, required } = useQuestionContext();
  const {
    serviceRequestAnswers,
    setServiceRequestAnswers,
    answersHaveError,
    setAnswersHaveError,
  } = useServiceRequestContext();

  const answer = useMemo(() => {
    const currentAnswer = serviceRequestAnswers.get(id);

    if (currentAnswer?.type === 'choices') {
      return undefined;
    }

    return currentAnswer;
  }, [id, serviceRequestAnswers]);

  const error = useMemo(
    () => (answersHaveError && required ? t('errors.required') : undefined),
    [answersHaveError, required, t]
  );

  const handleChange = useCallback(
    (value: string) => {
      setAnswersHaveError(false);
      if (new Graphemer().countGraphemes(value) > MAX_ANSWER_LENGTH) return;
      setServiceRequestAnswers((prev) => {
        const prevCopy = structuredClone(prev);

        prevCopy.set(id, {
          type: 'text',
          text: value,
        });

        const answer = prevCopy.get(id);

        if (answer?.type === 'text' && !answer.text) {
          prevCopy.delete(id);
        }

        return prevCopy;
      });
    },
    [MAX_ANSWER_LENGTH, id, setAnswersHaveError, setServiceRequestAnswers]
  );

  return {
    answer,
    error,
    handleChange,
  };
};
