import styled from '@emotion/styled';
import { ButtonV4, Colors, Search } from '@robinpowered/design-system';
import { MultiSelect } from 'components/ServiceForm/components/MultiSelect';
import { useTranslation } from 'react-i18next';
import { useSelectedBuildingTableContext } from '../contexts';
import { Close } from '@robinpowered/icons';

export const Filters = () => {
  const { t } = useTranslation('ServiceSection');
  const {
    floorOptions,
    selectedFloors,
    onSelectFloorOption,
    setSearchValue,
    searchValue,
    onSelectSpaceType,
    selectedSpaceTypes,
    spaceTypeOptions,
    handleClearFilters,
  } = useSelectedBuildingTableContext();

  return (
    <Wrapper>
      <FiltersWrapper>
        <MultiSelect
          size="small"
          placeholder={t('location.table.filters.floor.placeholder')}
          value={selectedFloors}
          name={t('location.table.filters.floor.name')}
          options={floorOptions}
          inputText={
            selectedFloors.length
              ? selectedFloors.length === 1
                ? selectedFloors[0].label
                : t('location.table.filters.floor.label', {
                    count: selectedFloors.length,
                  })
              : ''
          }
          onSelectOption={onSelectFloorOption}
        />
        <MultiSelect
          size="small"
          value={selectedSpaceTypes}
          placeholder={t('location.table.filters.type.placeholder')}
          name={t('location.table.filters.type.name')}
          options={spaceTypeOptions}
          inputText={
            selectedSpaceTypes.length
              ? selectedSpaceTypes.length === 1
                ? selectedSpaceTypes[0].label
                : t('location.table.filters.type.label', {
                    count: selectedSpaceTypes.length,
                  })
              : ''
          }
          onSelectOption={onSelectSpaceType}
        />
        {(selectedFloors.length > 0 ||
          selectedSpaceTypes.length > 0 ||
          searchValue.length > 0) && (
          <ButtonV4
            iconLeft={Close}
            label={t('location.table.filters.clear')}
            onClick={handleClearFilters}
            variant="tertiary"
            size="xSmall"
          />
        )}
      </FiltersWrapper>

      <Search
        size="small"
        buttonLabel={t('location.table.filters.search.button_label')}
        placeholder={t('location.table.filters.search.placeholder')}
        name={t('location.table.filters.search.name')}
        value={searchValue}
        onChange={setSearchValue}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  border-radius: 0 4px;
  background-color: ${Colors.Gray5};
  justify-content: space-between;
  padding-bottom: 16px;
`;

const FiltersWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
