import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Heading, Row } from '@robinpowered/design-system';
import { WarningSolid } from '@robinpowered/icons';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldLabel = styled.label`
  ${Heading.Small.styles}
  margin-bottom: 4px;
`;

export const FieldByline = styled(Row)`
  align-items: center;
  margin-top: 4px;
  gap: 4px;
  color: ${({ theme }) => theme.colorTokens.text.body};
  ${Body.Small.styles}
`;

export const ErrorByline = styled(FieldByline)`
  color: ${({ theme }) => theme.colorTokens.utilities.negative};
`;

export const FieldError = ({ message }: { message: string }): JSX.Element => {
  const theme = useTheme();
  return (
    <ErrorByline>
      <WarningSolid size={16} color={theme.colorTokens.utilities.negative} />
      {message}
    </ErrorByline>
  );
};
