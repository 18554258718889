import { useRef, useState } from 'react';

export const useManageOptionImage = () => {
  const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);
  const openImageModalRef = useRef<HTMLDivElement>(null);
  const replaceImageRef = useRef<HTMLDivElement>(null);

  const handleCloseImageUploadModal = () => {
    setImageUploadModalOpen(false);
    if (openImageModalRef.current) {
      (openImageModalRef.current?.firstChild as HTMLElement).focus();
    } else if (replaceImageRef.current) {
      replaceImageRef.current.focus();
    }
  };

  return {
    openImageModalRef,
    replaceImageRef,
    imageUploadModalOpen,
    setImageUploadModalOpen,
    handleCloseImageUploadModal,
  };
};
