import styled from '@emotion/styled';
import { SelectLocations } from './SelectLocations';

export const MeetingServicesFilters = () => {
  return (
    <MeetingServiceFilterWrapper>
      <SelectLocations />
    </MeetingServiceFilterWrapper>
  );
};

const MeetingServiceFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
