import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { Header, HeaderProps } from './Header';

type ManageModalProps = {
  leftHeaderSlot?: HeaderProps['leftSlot'];
  rightHeaderSlot?: HeaderProps['rightSlot'];
  justifyRightHeaderSlot?: HeaderProps['justifyRightSlot'];
  justifyLeftHeaderSlot?: HeaderProps['justifyLeftSlot'];
  bottomContentSlot?: JSX.Element;
  showBackgroundPattern?: boolean;
} & Omit<
  HeaderProps,
  'leftSlot' | 'rightSlot' | 'justifyRightSlot' | 'justifyLeftSlot'
>;

export const FullscreenModal: FC<ManageModalProps> = ({
  leftHeaderSlot,
  rightHeaderSlot,
  bottomContentSlot,
  title,
  subtitle,
  showBackgroundPattern = false,
  justifyLeftHeaderSlot,
  justifyRightHeaderSlot,
  children,
}) => {
  useEffect(() => {
    const windowOffset = window.scrollY;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.removeProperty('overflow');
      window.scrollTo(0, windowOffset);
    };
  }, []);

  return (
    <Container showBackgroundPattern={showBackgroundPattern}>
      <Header
        leftSlot={leftHeaderSlot}
        rightSlot={rightHeaderSlot}
        justifyLeftSlot={justifyLeftHeaderSlot}
        justifyRightSlot={justifyRightHeaderSlot}
        title={title}
        subtitle={subtitle}
      />
      <Body>{children}</Body>
      {bottomContentSlot}
    </Container>
  );
};

const backgroundPattern =
  /* eslint-disable-next-line max-len */
  'iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdAQMAAABsXfVMAAAABlBMVEXi5Obs7/FjD3jQAAAAHklEQVR4nGO0P8DwkLG+geEj438Ghp8DRoBdAHILACIROPZyGaZ2AAAAAElFTkSuQmCC';

const background = `url(data:image/png;base64, ${backgroundPattern})`;

const Container = styled.div<{ showBackgroundPattern: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: ${({ showBackgroundPattern }) =>
    showBackgroundPattern ? `  ${background} repeat;` : Colors.Tan30};
`;

const Body = styled.div`
  height: 100%;
  padding-bottom: 40px;
  overflow-y: auto;
`;
