import React, { createContext, useContext } from 'react';
import { useCurrencySelect } from '../components/hooks/useCurrencySelect';

type QuestionSectionType = {
  selectedCurrency: {
    code: string;
    symbol: string | undefined;
  };
  commonCurrencies: {
    label: string;
    value: string;
  }[];
  supportedCurrency: {
    label: string;
    value: string;
  }[];
  handleSelectCurrency: (value: string) => void;
};

const QuestionSectionContext = createContext<QuestionSectionType>({
  selectedCurrency: {
    code: 'USD',
    symbol: '$',
  },
  commonCurrencies: [],
  supportedCurrency: [],
  handleSelectCurrency: () => null,
});

export const QuestionSectionProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const {
    selectedCurrency,
    commonCurrencies,
    supportedCurrency,
    handleSelectCurrency,
  } = useCurrencySelect();

  return (
    <QuestionSectionContext.Provider
      value={{
        selectedCurrency,
        commonCurrencies,
        supportedCurrency,
        handleSelectCurrency,
      }}
    >
      {children}
    </QuestionSectionContext.Provider>
  );
};

export const useQuestionSectionContext = (): QuestionSectionType => {
  return useContext(QuestionSectionContext);
};
