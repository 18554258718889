import { MeetingServiceType } from 'components';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export const commonLocales = [
  {
    locale: 'en-GB',
    currencyCode: 'GBP',
  },
  {
    locale: 'en-US',
    currencyCode: 'USD',
  },
  {
    locale: 'es-AR',
    currencyCode: 'ARS',
  },
  {
    locale: 'en-AU',
    currencyCode: 'AUD',
  },
  {
    locale: 'fr-FR',
    currencyCode: 'EUR',
  },
  {
    locale: 'es-ES',
    currencyCode: 'EUR',
  },
  {
    locale: 'bg-BG',
    currencyCode: 'BGN',
  },
  {
    locale: 'pt-BR',
    currencyCode: 'BRL',
  },
  {
    locale: 'pt-BR',
    currencyCode: 'BRL',
  },
  {
    locale: 'en-CA',
    currencyCode: 'CAD',
  },
  {
    locale: 'es-CL',
    currencyCode: 'CLP',
  },
  {
    locale: 'zh-CN',
    currencyCode: 'CNY',
  },
  {
    locale: 'es-CO',
    currencyCode: 'COP',
  },
  {
    locale: 'da-DK',
    currencyCode: 'DKK',
  },
  {
    locale: 'it-IT',
    currencyCode: 'EUR',
  },
  {
    locale: 'hi-IN',
    currencyCode: 'INR',
  },
  {
    locale: 'he-IL',
    currencyCode: 'ILS',
  },
  {
    locale: 'ja-JP',
    currencyCode: 'JPY',
  },
  {
    locale: 'ko-KR',
    currencyCode: 'KRW',
  },
  {
    locale: 'es-MX',
    currencyCode: 'MXN',
  },
  {
    locale: 'nl-CW',
    currencyCode: 'ANG',
  },
  {
    locale: 'en-NZ',
    currencyCode: 'NZD',
  },
  {
    locale: 'no-NO',
    currencyCode: 'NOK',
  },
  {
    locale: 'es-PE',
    currencyCode: 'PEN',
  },
  {
    locale: 'en-PH',
    currencyCode: 'PHP',
  },
  {
    locale: 'pl-PL',
    currencyCode: 'PLN',
  },
  {
    locale: 'ro-RO',
    currencyCode: 'RON',
  },
  {
    locale: 'ar-SA',
    currencyCode: 'SAR',
  },
  {
    locale: 'en-SG',
    currencyCode: 'SGD',
  },
  {
    locale: 'en-ZA',
    currencyCode: 'ZAR',
  },
  {
    locale: 'sv-SE',
    currencyCode: 'SEK',
  },
  {
    locale: 'de-CH',
    currencyCode: 'CHF',
  },
  {
    locale: 'de-DE',
    currencyCode: 'EUR',
  },
  {
    locale: 'uk-UA',
    currencyCode: 'UAH',
  },
];

export const commonCurrenciesByLocale = new Map(
  commonLocales.map((cl) => {
    return [cl.locale, cl.currencyCode];
  })
);

export const useCurrencySelect = () => {
  const { setValue, getValues } = useFormContext<MeetingServiceType>();
  const defaultCurrencyCode = getValues('currencyCode');
  const getCurrencySymbol = useCallback(
    (locale: string, currencyCode: string) => {
      const currency = new Intl.NumberFormat(locale, {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: currencyCode,
      })
        .formatToParts(0)
        .find((x) => x.type === 'currency')?.value;
      return currency;
    },
    []
  );

  /* Set a default currency code based on the users locale */
  const [selectedCurrency, setSelectedCurrency] = useState({
    code: defaultCurrencyCode,
    symbol: getCurrencySymbol(navigator.language, defaultCurrencyCode),
  });

  const handleSelectCurrency = useCallback(
    (value) => {
      setSelectedCurrency({
        code: value,
        symbol: getCurrencySymbol(navigator.language, value),
      });
      setValue('currencyCode', value);
    },
    [setSelectedCurrency, getCurrencySymbol, setValue]
  );

  const commonCurrencies = useMemo(
    () =>
      [...new Set(commonCurrenciesByLocale.values())].map((currencyCode) => ({
        label: currencyCode,
        value: currencyCode,
      })),
    []
  );

  const supportedCurrency = useMemo(() => {
    return Intl.supportedValuesOf('currency')
      .filter((currency: string) => {
        return ![...commonCurrenciesByLocale.entries()].some(([, value]) => {
          return value === currency;
        });
      })
      .map((currency: string) => ({ label: currency, value: currency }));
  }, []);

  return {
    commonCurrencies,
    supportedCurrency,
    selectedCurrency,
    handleSelectCurrency,
  };
};
