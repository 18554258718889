import { useTranslation } from 'react-i18next';
import { useToast } from 'contexts/ToastContext';

import { useCallback, useMemo, useState } from 'react';
import { AddItemButton } from 'components/ServiceForm/components';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useFormContext } from 'react-hook-form';
import {
  useAddMeetingServiceCategoryForFormMutation,
  useGetMeetingServiceCategoriesForFormQuery,
} from 'generated';
import { AddCategory } from './components';
import styled from '@emotion/styled';
import { useValidateCategory } from './hooks';
import { Select } from 'components/ServiceForm/components/Select/Select';
import { useAuthContext } from 'contexts';

export const Category = () => {
  const { t } = useTranslation('ServiceSection');
  const toast = useToast();
  const {
    setError,
    clearErrors,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<MeetingServiceType>();
  const { loading: authLoading } = useAuthContext();
  const { validateCategoryKey, validateCategoryName } = useValidateCategory();
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const category = watch('category');

  const { data, refetch } = useGetMeetingServiceCategoriesForFormQuery({
    // We may not want a limit in the future
    variables: {
      input: { first: 100 },
    },
    skip: authLoading,
  });

  const [addMeetingServiceCategory, { error }] =
    useAddMeetingServiceCategoryForFormMutation();

  const selectOptions = useMemo(
    () =>
      data?.listMeetingServicesCategories.meetingServicesCategories.map((c) => {
        return { value: c.name, label: c.name, id: c.id };
      }) || [],
    [data]
  );

  const updateSelectedCategory = useCallback(
    (name: string) => {
      const newSelectedCategory =
        data?.listMeetingServicesCategories.meetingServicesCategories.find(
          (c) => {
            return c.name.toLocaleLowerCase() === name.toLocaleLowerCase();
          }
        );

      if (newSelectedCategory) {
        setValue('category.name', newSelectedCategory.name);
        setValue('category.key', newSelectedCategory.key);
        setValue('category.id', newSelectedCategory.id);
        clearErrors('category.id');
      }
    },
    [
      data?.listMeetingServicesCategories.meetingServicesCategories,
      setValue,
      clearErrors,
    ]
  );

  const handleAddCategory = useCallback(
    (name: string, key: string) => {
      const listOfCategoryNames =
        data?.listMeetingServicesCategories.meetingServicesCategories.map(
          (c) => c.name
        );

      // Validate input
      const nameError = validateCategoryName(name, listOfCategoryNames);
      const keyError = validateCategoryKey(key);

      if (nameError) {
        setError('category.name', { type: 'custom', message: nameError });
      }
      if (keyError) {
        setError('category.key', { type: 'custom', message: keyError });
      }

      if (!key || !name || nameError || keyError) {
        return;
      }

      // Save data
      const addCategory = async () => {
        await addMeetingServiceCategory({
          variables: {
            categoryInput: {
              name,
              key,
            },
          },
          onCompleted: (res) => {
            toast.success(t(`service_details.toasts.success`));

            if (
              res.addMeetingServicesCategory.__typename ===
              'AddMeetingServicesCategorySuccessResponse'
            ) {
              setValue(
                'category.id',
                res.addMeetingServicesCategory.meetingServicesCategoryId
              );
            }

            refetch();
          },
          onError: () => {
            toast.error(t(`service_details.toasts.error`));
          },
        });
      };

      addCategory();

      if (!error) {
        setValue('category.name', name);
        setValue('category.key', key);
        setIsAddingCategory(false);
      }
    },
    [
      addMeetingServiceCategory,
      data?.listMeetingServicesCategories.meetingServicesCategories,
      error,
      refetch,
      setError,
      setValue,
      t,
      toast,
      validateCategoryKey,
      validateCategoryName,
    ]
  );

  return (
    <>
      {isAddingCategory ? (
        <AddCategory
          onSave={handleAddCategory}
          onCancel={() => {
            clearErrors(['category.name', 'category.key', `category.id`]);
            setIsAddingCategory(false);
          }}
        />
      ) : (
        <StyledAddWrapper>
          <Select
            name={t(`service_details.inputs.category.select.label`)}
            label={t(`service_details.inputs.category.select.label`)}
            value={{
              value: category.name,
              label: category.name,
              id: category.id,
            }}
            options={selectOptions}
            placeholder={t(
              `service_details.inputs.category.select.placeholder`
            )}
            onSelect={(value) => updateSelectedCategory(value.label)}
            error={errors.category?.id?.message}
          />
          <AddItemButton
            title={t(`service_details.add_category.text`)}
            ariaLabel={t(`service_details.add_category.label`)}
            onClick={() => setIsAddingCategory(true)}
            size="small"
          />
        </StyledAddWrapper>
      )}
    </>
  );
};

const StyledAddWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
