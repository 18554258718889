import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { QueryResult } from '@apollo/client';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import {
  Exact,
  GetBuildingsWhereUserCanManageMeetingServicesForFormQuery,
  useGetBuildingsWhereUserCanManageMeetingServicesForFormQuery,
} from 'generated';
import { useFormContext } from 'react-hook-form';

type SelectBuildingsType = {
  buildings:
    | QueryResult<
        GetBuildingsWhereUserCanManageMeetingServicesForFormQuery,
        Exact<{
          [key: string]: never;
        }>
      >
    | undefined;
  selectedBuildingIds: Set<string>;
  selectedBuildingsWithoutSpaces: Set<string>;
};

const SelectBuildingsContext = createContext<SelectBuildingsType>({
  buildings: undefined,
  selectedBuildingIds: new Set(),
  selectedBuildingsWithoutSpaces: new Set(),
});

export const SelectBuildingsProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const buildings =
    useGetBuildingsWhereUserCanManageMeetingServicesForFormQuery();
  const { watch, clearErrors } = useFormContext<MeetingServiceType>();
  const selectedBuildings = watch('availableInSpacesByBuilding');

  const selectedBuildingIds = useMemo(() => {
    return new Set(selectedBuildings.keys());
  }, [selectedBuildings]);

  const selectedBuildingsWithoutSpaces = useMemo(() => {
    return new Set(
      [...selectedBuildingIds].filter(
        (id) => selectedBuildings.get(id)?.size === 0
      )
    );
  }, [selectedBuildings, selectedBuildingIds]);

  useEffect(() => {
    if (selectedBuildingsWithoutSpaces.size === 0) {
      clearErrors('availableInSpacesByBuilding');
    }
  }, [selectedBuildingsWithoutSpaces, clearErrors]);

  return (
    <SelectBuildingsContext.Provider
      value={{
        buildings,
        selectedBuildingIds,
        selectedBuildingsWithoutSpaces,
      }}
    >
      {children}
    </SelectBuildingsContext.Provider>
  );
};

export const useSelectBuildingsContext = (): SelectBuildingsType => {
  return useContext(SelectBuildingsContext);
};
