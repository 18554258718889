import styled from '@emotion/styled';
import {
  Body,
  ButtonV4,
  Colors,
  Flex,
  UtilityButton,
} from '@robinpowered/design-system';
import { TrashOutline } from '@robinpowered/icons';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

type OptionProps = {
  onChangeImage: (img: string | null) => void;
  replaceImageRef: RefObject<HTMLDivElement>;
  setImageUploadModalOpen: (isOpen: boolean) => void;
  imageUrl: string;
  name: string;
};

export const ImageInput = ({
  onChangeImage,
  replaceImageRef,
  setImageUploadModalOpen,
  imageUrl,
  name,
}: OptionProps) => {
  const { t } = useTranslation('QuestionsSection');

  return (
    <ImageContainer>
      <Body.Small style={{ minWidth: '120px', alignSelf: 'start' }}>
        {t('image_upload.title')}
      </Body.Small>

      <UploadedImageContainer>
        <ReplaceUploadedImageOverlay
          onClick={() => setImageUploadModalOpen(true)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') setImageUploadModalOpen(true);
          }}
          className="imageOverlay"
          ref={replaceImageRef}
          tabIndex={0}
        >
          <ButtonV4
            variant="secondary"
            size="small"
            label={t('image_upload.replace')}
            onClick={() => setImageUploadModalOpen(true)}
          />
        </ReplaceUploadedImageOverlay>
        <UploadedImage>
          <img
            src={imageUrl}
            loading="lazy"
            alt={name}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </UploadedImage>
      </UploadedImageContainer>

      <div title={t('image_upload.remove')}>
        <UtilityButton
          icon={TrashOutline}
          onClick={() => onChangeImage(null)}
        />
      </div>
    </ImageContainer>
  );
};

const ImageContainer = styled(Flex)`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-basis: 100%;
`;

const ReplaceUploadedImageOverlay = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;

  align-items: center;
  justify-content: center;

  button {
    display: none;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);

    button {
      display: initial;
    }
  }
}
`;

const UploadedImageContainer = styled(Flex)`
  display: flex;
  padding: 8px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid ${Colors.Gray40};
  width: fit-content;

  width: 160px;
  height: 160px;
  position: relative;
`;

const UploadedImage = styled.div`
  clip-path: inset(0%);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
