// Common breakpoints we will use moving forward
// @TODO: These should be exported from component library

import { css } from '@emotion/react';

export enum Breakpoints {
  LG = '1360px',
  MD = '960px',
  SM = '360px',
}

export const Ellipsis = css`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ServiceFormContainerWidths = `926px`;
