import styled from '@emotion/styled';
import { Body, Colors, Heading } from '@robinpowered/design-system';

type Props = {
  children: React.ReactNode;
  title?: string;
  description?: string;
  leftSide?: JSX.Element;
  hasError?: boolean;
};

/* A common wrapper for each section in the settings */
export const SectionWrapper = ({
  title,
  description,
  leftSide,
  children,
  hasError,
}: Props) => {
  return (
    <Wrapper style={hasError ? { border: `1px solid ${Colors.Red100}` } : {}}>
      <Header>
        <div>
          <Heading.Medium>{title}</Heading.Medium>
          <Body.Small>{description}</Body.Small>
        </div>
        {leftSide}
      </Header>
      {children}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 16px;
  padding: 24px;
  background: ${Colors.White0};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colorTokens.stroke.layer2};

  padding: 24px;
  text-align: left;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
