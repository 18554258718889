import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Heading04 } from '@robinpowered/design-system';
import { Colors } from '@robinpowered/design-system';
import { Body07 } from '@robinpowered/design-system';

type JustifyOptions =
  | 'start'
  | 'end'
  | 'center'
  | 'space-between'
  | 'space-evenly';

type JustifiableContainerProps = { justify: JustifyOptions };

export type HeaderProps = {
  leftSlot?: JSX.Element;
  rightSlot?: JSX.Element;
  title: string;
  subtitle?: string;
  justifyRightSlot?: JustifyOptions;
  justifyLeftSlot?: JustifyOptions;
};

const getJustificationOption = (option: JustifyOptions): string => {
  switch (option) {
    case 'start':
      return 'flex-start';
    case 'end':
      return 'flex-end';
    default:
      return option;
  }
};

export function Header({
  leftSlot,
  rightSlot,
  title,
  subtitle,
  justifyLeftSlot,
  justifyRightSlot,
}: HeaderProps): JSX.Element {
  return (
    <Container>
      <LeftColumn justify={justifyLeftSlot || 'start'}>{leftSlot}</LeftColumn>
      <CenterColumn>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </CenterColumn>
      <RightColumn justify={justifyRightSlot || 'end'}>{rightSlot}</RightColumn>
    </Container>
  );
}

const Container = styled.header`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 8px;
  background-color: ${Colors.Gray100};
  justify-content: space-between;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 12px rgba(0, 0, 0, 0.06);
`;

const baseColumn = css`
  display: flex;
  align-items: center;
`;

const LeftColumn = styled.div<JustifiableContainerProps>`
  ${baseColumn}
  flex: 1;
  justify-content: ${({ justify }): string => getJustificationOption(justify)};
`;

const CenterColumn = styled.div`
  display: flex;
  max-width: 50%;
`;

const RightColumn = styled.div<JustifiableContainerProps>`
  ${baseColumn}
  flex: 1;
  justify-content: ${({ justify }): string => getJustificationOption(justify)};
`;

const Title = styled(Heading04)`
  line-height: 22px;
  color: ${Colors.White0};
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Subtitle = styled(Body07)`
  color: ${Colors.Gray40};
`.withComponent('p');
