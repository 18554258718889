import { ButtonV4, Colors } from '@robinpowered/design-system';
import { Add } from '@robinpowered/icons';
import { PersonGroupSearch } from '../../../components/PersonGroupSearch/PersonGroupSearch';
import { useSelectFacilitatorContext } from '../contexts';
import { useTranslation } from 'react-i18next';

export const AddFacilitator = () => {
  const { t } = useTranslation('ServiceSection');
  const {
    users,
    groups,
    keyword,
    setKeyword,
    selectGroupFacilitator,
    selectIndividualFacilitator,
    loading,
    closePersonGroupSearch,
    showPersonGroupSearch,
    setShowPersonGroupSearch,
  } = useSelectFacilitatorContext();

  return (
    <div>
      {showPersonGroupSearch ? (
        <PersonGroupSearch
          users={users}
          groups={groups}
          keyword={keyword}
          onChange={setKeyword}
          selectGroup={selectGroupFacilitator}
          selectIndividual={selectIndividualFacilitator}
          loading={loading}
          onClose={closePersonGroupSearch}
        />
      ) : (
        <ButtonV4
          variant="secondary"
          size="small"
          onClick={() => {
            setShowPersonGroupSearch(true);
          }}
          label={t('facilitator.add_person_group') || ''}
          iconLeft={() => <Add color={Colors.Gray60} />}
        />
      )}
    </div>
  );
};
