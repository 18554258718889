import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import {
  Body,
  Colors,
  SpinnerLoader,
  UtilityButton,
  useEscape,
  useClickOutside,
  BrownFontStack,
  CheckboxV4,
} from '@robinpowered/design-system';
import ChevronDownOutline from '@robinpowered/icons/ChevronDownOutline';
import { useTheme } from '@emotion/react';
import { FieldByline, FieldContainer, FieldError, FieldLabel } from '../Fields';

export type Option = {
  value: string;
  label: string;
  indeterminate?: boolean;
};

interface Props {
  /** The controlled value {value: '', label: ''}> If label omitted, the value is used */
  value: Option[];
  /** An array of options {value: '', label: ''} */
  options: Option[];
  /** Sets the name and aria-label for the input */
  name: string;
  /** Label rendered on top of the input. */
  label?: string;
  // Takes a new value object
  onSelectOption: (value: Option) => void;
  /** Text rendered in dropdown when there is no matching result */
  noResultsText?: string;
  /** Placeholder rendered within the input. */
  placeholder?: string;
  /** When the dropdown items are loading, renders a loader in the input */
  isLoading?: boolean;
  /** Style properties applied to the input's container */
  style?: React.CSSProperties | undefined;
  /** Byline rendered below the input. */
  byline?: string;
  /** Error rendered below the input. Will override the byline. */
  error?: string;
  /** Icon rendered from the Robinkit icon library.  Will render on the left side within the input. */
  icon?: FC<{
    color: string;
    size: number;
  }>;
  /** Different sizings: 'small' | 'medium'. */
  size?: 'small' | 'medium';
  /** Blocks user action. */
  disabled?: boolean;
  inputText?: string;
  selectAllOption?: Option & { onClick: () => void; checked: boolean };
}

export const MultiSelect = ({
  options = [],
  selectAllOption,
  isLoading = false,
  onSelectOption,
  noResultsText,
  placeholder = 'Search or select',
  name,
  style,
  error,
  byline,
  disabled,
  size = 'medium',
  label,
  icon: Icon,
  value = [],
  inputText,
}: Props): JSX.Element => {
  const { colorTokens } = useTheme();
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);

  const [searchText, setSearchText] = useState('');

  const getInputText = useCallback(() => {
    return inputText
      ? inputText
      : value.length
      ? `${value.length} selected`
      : '';
  }, [inputText, value]);

  useEffect(() => {
    // Whenever we do something to update the inputText value outside of the input,
    // we must keep it up to date
    if (!open) {
      setSearchText(getInputText());
    }
  }, [getInputText, inputText, open]);

  useClickOutside(() => {
    setOpen(false);
  }, containerRef);
  useEscape(() => {
    setOpen(false);
  });

  const filteredOptions = useMemo(() => {
    return options.filter((option) => {
      return option.label
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase());
    });
  }, [options, searchText]);

  const findMatchedOption = (options: Option[], value: string) =>
    options.find((option) => option.value === value || option?.label === value);

  const isOptionSelected = useCallback(
    (option: Option): boolean => {
      return !!value.find((v) => v.value === option.value);
    },
    [value]
  );

  const dropdownOptions =
    findMatchedOption(options, searchText) && searchText
      ? options
      : filteredOptions;

  const canInteractWithInput = !disabled && !isLoading;

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (!open && canInteractWithInput) {
        setOpen(true);
      }

      setSearchText(e.target.value);
    },
    [canInteractWithInput, open]
  );

  return (
    <FieldContainer style={style}>
      {label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}

      <Container
        ref={containerRef}
        robinSize={size}
        aria-disabled={disabled}
        disabled={disabled}
      >
        {Icon && (
          <IconWrapper>
            <Icon color={Colors.Gray60} size={size === 'medium' ? 16 : 14} />
          </IconWrapper>
        )}
        <Input
          robinSize={size}
          disabled={disabled}
          name={name}
          aria-label={name}
          placeholder={placeholder}
          onClick={(): void => {
            if (canInteractWithInput) {
              setSearchText('');
              setOpen(true);
            }
          }}
          value={searchText}
          onChange={handleInputChange}
        />

        {isLoading && (
          <>
            <LoaderContainer>
              <SpinnerLoader
                height={14}
                width={14}
                color={colorTokens.icon.default}
              />
            </LoaderContainer>
            <VerticalLine />
          </>
        )}

        {/* @TODO: Bug with the utility button we cant handle on enter to close dropdown  */}
        <UtilityButton
          // eslint-disable-next-line react/jsx-no-literals
          aria-label={'Open options list'}
          icon={ChevronDownOutline}
          style={{
            height: '28px',
            width: '28px',
            transform: `rotate(${open ? 180 : 0}deg)`,
          }}
          onClick={(): void => {
            if (canInteractWithInput) {
              if (open) {
                setOpen(false);
              } else {
                setSearchText('');
                setOpen(true);
              }
            }
          }}
        />
        {open && !isLoading && (
          <OptionMenu>
            {selectAllOption && (
              <OptionItem
                key={selectAllOption.value}
                tabIndex={0}
                onClick={() => {
                  setSearchText('');
                  selectAllOption.onClick();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSearchText('');
                    selectAllOption.onClick();
                  }
                }}
              >
                <CheckboxV4
                  checked={selectAllOption.checked}
                  indeterminate={selectAllOption.indeterminate}
                  onChange={() => null}
                />
                <OptionItemText as="p">
                  {selectAllOption?.label || selectAllOption.value}
                </OptionItemText>
              </OptionItem>
            )}
            {dropdownOptions.map((option, index) => {
              return (
                <OptionItem
                  key={index}
                  tabIndex={0}
                  selected={isOptionSelected(option)}
                  onClick={() => {
                    setSearchText('');
                    onSelectOption(option);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setSearchText('');
                      onSelectOption(option);
                    }
                  }}
                >
                  <CheckboxV4
                    checked={isOptionSelected(option)}
                    indeterminate={option.indeterminate}
                    onChange={() => null}
                  />
                  <OptionItemText as="p">
                    {option?.label || option.value}
                  </OptionItemText>
                </OptionItem>
              );
            })}
            {!dropdownOptions.length && value && (
              <OptionItem>
                <Body.Small>{noResultsText || 'No results'}</Body.Small>
              </OptionItem>
            )}
          </OptionMenu>
        )}
      </Container>
      {!error && byline && <FieldByline>{byline}</FieldByline>}
      {error && <FieldError message={error} />}
    </FieldContainer>
  );
};

const IconWrapper = styled.div`
  margin: 6px 0px 6px 6px;
  height: 16px;
  width: 16px;
  display: flex;
`;

type ContainerProps = {
  robinSize: 'small' | 'medium';
  disabled?: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid
    ${({ theme, disabled }): string =>
      disabled ? theme.colorTokens.stroke.layer1 : Colors.Gray60};
  border-radius: 4px;
  cursor: text;
  padding: 4px;
  height: ${({ robinSize }): string =>
    robinSize === 'medium' ? '40px' : '32px'};
  background-color: ${({ theme, disabled }): string =>
    disabled ? theme.colorTokens.bg.layer2 : theme.colorTokens.main.white};
  &:focus-within {
    outline: 3px solid ${({ theme }) => theme.colorTokens.focus.focus};
  }
`;

type InputProps = {
  robinSize: 'small' | 'medium';
  disabled?: boolean;
  error?: boolean;
};

const Input = styled.input<InputProps>`
  margin-left: 6px;
  border: none;
  ${({ robinSize }) =>
    robinSize === 'medium' ? Body.Regular.styles : Body.Small.styles};
  flex: 1;
  color: ${({ theme, disabled }): string =>
    disabled ? theme.colorTokens.text.disabled : theme.colorTokens.text.body};
  background-color: ${({ theme, disabled }): string =>
    disabled ? theme.colorTokens.bg.layer2 : theme.colorTokens.main.white};

  &::placeholder {
    color: ${({ theme }): string => theme.colorTokens.text.disabled};
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }
`;

const VerticalLine = styled.span`
  height: 20px;
  margin: 4px;
  border-right: 1px solid
    ${({ theme }): string => theme.colorTokens.stroke.layer3};
`;

const OptionMenu = styled.ul(({ theme }) => ({
  border: `1px solid ${theme.colorTokens.stroke.layer3}`,
  backgroundColor: Colors.White0,
  borderRadius: '4px',
  boxShadow: '0 1px 2px 0 rgba(0,0,0,0.1)',
  boxSizing: 'border-box',
  fontFamily: `${BrownFontStack}`,
  fontSize: theme.fontSizes[2],
  left: 0,
  maxHeight: '300px',
  overflow: 'auto',
  position: 'absolute',
  top: '110%',
  userSelect: 'none',
  width: '100%',
  zIndex: 999,
}));

const OptionItem = styled.li<{ selected?: boolean }>(({ selected }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  background: `${selected ? Colors.Tan10 : 'transparent'}`,
  listStyle: 'none',
  gap: '8px',

  '&:hover, &:focus': {
    background: `${Colors.Tan10}`,
  },
}));

const OptionItemText = styled(Body.Small)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
`;

const LoaderContainer = styled.div`
  right: 8px;
  top: 8px;
  width: 18px;
  height: 18px;
`;
