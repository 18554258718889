import { useTranslation } from 'react-i18next';
import { SectionWrapper } from '../../components';
import { Colors } from '@robinpowered/design-system';
import { AddFacilitator } from './components/AddFacilitator';
import {
  SelectFacilitatorProvider,
  useSelectFacilitatorContext,
} from './contexts';
import styled from '@emotion/styled';
import { IndividualFacilitators, GroupFacilitators } from './components';
import { EmptyMessage } from 'components/common/EmptyMessage';

export const Approver = () => {
  const { t } = useTranslation('ServiceSection');

  return (
    <SelectFacilitatorProvider facilitatorType={'approvers'}>
      <SectionWrapper
        title={t('approver.title')}
        description={t('approver.description')}
        leftSide={<AddFacilitator />}
      >
        <ApproverList />
      </SectionWrapper>
    </SelectFacilitatorProvider>
  );
};

const ApproverList = () => {
  const { t } = useTranslation('ServiceSection');
  const { groupFacilitator, individualFacilitator } =
    useSelectFacilitatorContext();

  // We can assert
  if (!groupFacilitator?.length && !individualFacilitator?.length) {
    return (
      <EmptyMessage
        title={t('approver.no_approver_title')}
        description={t('approver.no_approver_description')}
      />
    );
  }

  return (
    <Wrapper>
      {individualFacilitator?.length ? (
        <IndividualFacilitators />
      ) : (
        <GroupFacilitators />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${Colors.Gray20};
  border-radius: 4px;
`;
