import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import {
  ServiceRequestContextProvider,
  useServiceRequestContext,
} from './contexts/ServiceRequestContext';
import { DetailCard } from './components/DetailCard/DetailCard';
import { RobinLoader } from 'components/RobinLoader';
import { SelectCategory } from './components/DetailCard/SelectCategory';
import { SelectService } from './components/DetailCard/SelectService';
import { ServiceQuestions } from './components/DetailCard/ServiceQuestions';
import { MarkdownRenderer } from '../common/MarkdownRenderer/MarkdownRenderer';

const ServiceRequest = () => {
  const { loading, selectedServiceWithQuestions } = useServiceRequestContext();

  if (loading) {
    return <RobinLoader />;
  }

  return (
    <Wrapper>
      <DetailCard />
      <SelectCategory />
      <SelectService />
      {selectedServiceWithQuestions &&
        selectedServiceWithQuestions.description && (
          <MarkdownRenderer
            markdownString={selectedServiceWithQuestions.description}
          />
        )}
      <ServiceQuestions />
    </Wrapper>
  );
};

export const ServiceRequestContainer = () => {
  return (
    <ServiceRequestContextProvider>
      <ServiceRequest />
    </ServiceRequestContextProvider>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  background-color: ${Colors.White0};
  padding: 16px;

  overflow-y: auto;
`;
