import { useTranslation } from 'react-i18next';
import { SectionWrapper } from '../../components';
import { Category, Name } from './components';
import { ServiceDescription } from './components/ServiceDescription';
import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';

export const ServiceDetails = () => {
  const { t } = useTranslation('ServiceSection');
  const { formState } = useFormContext<MeetingServiceType>();
  const hasError =
    !!formState.errors?.serviceName || !!formState.errors?.category;

  return (
    <SectionWrapper title={t(`service_details.title`)} hasError={hasError}>
      <Category />
      <Name />
      <ServiceDescription />
    </SectionWrapper>
  );
};
