import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useCallback, useMemo } from 'react';
import {
  UITicketQuestion,
  UITicketQuestionExpectingChoices,
  buildEmptyOption,
  isMultiChoiceQuestionType,
  updateQuestionType,
} from 'types';
import { v4 as uuidv4 } from 'uuid';
import { useManageQuestions } from '../hooks/useManageQuestions';
import { QuestionDropdownTypes } from './QuestionBuilder';
import Graphemer from 'graphemer';

const MAX_PROMPT_LENGTH = 200;

export type UseManageQuestionAPI = {
  handleSelectDropdown: (
    type: QuestionDropdownTypes,
    question: UITicketQuestion
  ) => void;
  handleUpdatePrompt: (text: string, question: UITicketQuestion) => void;
  handleAddOption: (question: UITicketQuestion) => void;
  handleToggleRequired: (question: UITicketQuestion) => void;
  handleToggleMultiSelect: (question: UITicketQuestionExpectingChoices) => void;
  handleDeleteQuestion: (index: number) => void;
  firstQuestionWithEmptyPromptById: string | undefined;
};

// Hook for management of individual 'questions'
export const useManageQuestion = (): UseManageQuestionAPI => {
  const { clearErrors, watch, setValue, getValues } =
    useFormContext<MeetingServiceType>();
  const questions = watch('questions');
  const { getUpdatedQuestionsList } = useManageQuestions();

  const firstQuestionWithEmptyPromptById = useMemo(() => {
    return questions.find((q) => q.hasEmptyContent)?.id;
  }, [questions]);

  const handleUpdatePrompt = useCallback(
    (text: string, question: UITicketQuestion) => {
      if (new Graphemer().countGraphemes(text) > MAX_PROMPT_LENGTH) {
        return;
      }
      const hasEmptyContent =
        !text.trim().length ||
        (isMultiChoiceQuestionType(question) &&
          question.options.some((o) => !o.name.trim().length));

      const updatedQuestions = getUpdatedQuestionsList({
        ...question,
        prompt: text,
        hasEmptyContent,
      });

      setValue('questions', updatedQuestions);
    },
    [getUpdatedQuestionsList, setValue]
  );

  const handleSelectDropdown = useCallback(
    (type: QuestionDropdownTypes, question: UITicketQuestion) => {
      const updatedQuestions = questions.map((q) => {
        return q.id === question.id
          ? updateQuestionType(question, type, getValues('currencyCode'))
          : q;
      });

      setValue('questions', updatedQuestions);
    },
    [questions, setValue, getValues]
  );

  const handleAddOption = useCallback(
    (question: UITicketQuestion) => {
      if (isMultiChoiceQuestionType(question)) {
        const updatedQuestions = getUpdatedQuestionsList({
          ...question,
          hasEmptyContent: true,
          options: [
            ...question.options,
            buildEmptyOption(
              uuidv4(),
              question.type,
              getValues('currencyCode')
            ),
          ],
        } as UITicketQuestion);
        setValue('questions', updatedQuestions);
      }
    },
    [getUpdatedQuestionsList, setValue, getValues]
  );

  const handleToggleRequired = useCallback(
    (question: UITicketQuestion) => {
      const updatedQuestions = getUpdatedQuestionsList({
        ...question,
        required: !question.required,
      });
      setValue('questions', updatedQuestions);
    },
    [getUpdatedQuestionsList, setValue]
  );

  const handleToggleMultiSelect = useCallback(
    (question: UITicketQuestionExpectingChoices) => {
      const updatedQuestions = getUpdatedQuestionsList({
        ...question,
        chooseOnlyOneOption: !question.chooseOnlyOneOption,
      });
      setValue('questions', updatedQuestions);
    },
    [getUpdatedQuestionsList, setValue]
  );

  const handleDeleteQuestion = useCallback(
    (index: number) => {
      const prevQuestions = questions.filter((_, i) => index !== i);
      clearErrors('questions');
      setValue('questions', prevQuestions);
    },
    [questions, setValue, clearErrors]
  );
  return {
    handleUpdatePrompt,
    handleSelectDropdown,
    handleAddOption,
    handleToggleRequired,
    handleToggleMultiSelect,
    handleDeleteQuestion,
    firstQuestionWithEmptyPromptById,
  };
};
