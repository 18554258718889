import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  GetMeetingServicesForAdminTableListQuery,
  InputMaybe,
  ListMeetingServicesFiltersInput,
  ListMeetingServicesSortByInput,
  useGetMeetingServicesCountForTablePaginationQuery,
  useGetMeetingServicesForAdminTableListQuery,
} from 'generated';
import { useMeetingServicesLocationContext } from './LocationContext';
import { useTenantLocalStorage } from 'components/MeetingServices/hooks/useTenantLocalStorage';

type RefetchFilters = {
  skip?: InputMaybe<number> | undefined;
  after?: string | null | undefined;
};
type MeetingServicesValue = {
  meetingServicesData: GetMeetingServicesForAdminTableListQuery | undefined;
  refetchMeetingServices: (filters?: RefetchFilters) => void;
  meetingServicesCount: number;
  refetchMeetingServicesCount: () => void;
  loading: boolean;
  filtersForMeetingServicesQuery: ListMeetingServicesFiltersInput | undefined;
  setFiltersForMeetingServicesQuery: Dispatch<
    SetStateAction<ListMeetingServicesFiltersInput | undefined>
  >;
  sortByForMeetingServicesQuery:
    | ListMeetingServicesSortByInput
    | undefined
    | null;
  setSortByForMeetingServicesQuery: Dispatch<
    SetStateAction<ListMeetingServicesSortByInput | undefined | null>
  >;
};

const MeetingServicesContext = createContext<MeetingServicesValue>({
  meetingServicesData: undefined,
  refetchMeetingServices: () => null,
  meetingServicesCount: 0,
  refetchMeetingServicesCount: () => null,
  loading: false,
  filtersForMeetingServicesQuery: undefined,
  setFiltersForMeetingServicesQuery: () => null,
  sortByForMeetingServicesQuery: undefined,
  setSortByForMeetingServicesQuery: () => null,
});

type Props = {
  children: ReactNode;
};

export const ITEMS_PER_TABLE_PAGE = 15;

export const MeetingServicesContextProvider: FC<Props> = ({ children }) => {
  // TODO: if user does not have permission to manage services in any building
  // then we should redirect away or show error message
  const {
    selectedLocations,
    loading: loadingLocations,
    locationsUserCanManage,
  } = useMeetingServicesLocationContext();

  const availableInAnyBuildingIdsOrTheirDescendants = useMemo(() => {
    return selectedLocations.length
      ? selectedLocations.filter((location) => !!location)
      : locationsUserCanManage.map((location) => {
          return location?.id || '';
        });
  }, [selectedLocations, locationsUserCanManage]);

  const [filtersForMeetingServicesQuery, setFiltersForMeetingServicesQuery] =
    useTenantLocalStorage<ListMeetingServicesFiltersInput>(
      'meeting-service-admin-table-filters',
      {
        belongsToAnyCategoryId: [],
        assignedToAny: {
          userIds: [],
          groupIds: [],
        },
      }
    );

  const [sortByForMeetingServicesQuery, setSortByForMeetingServicesQuery] =
    useTenantLocalStorage<ListMeetingServicesSortByInput | null>(
      'meeting-service-admin-table-sort',
      null
    );

  const {
    data: meetingServicesData,
    refetch: refetchMeetingServices,
    loading,
  } = useGetMeetingServicesForAdminTableListQuery({
    variables: {
      input: {
        first: ITEMS_PER_TABLE_PAGE,
        filters: {
          ...filtersForMeetingServicesQuery,
          availableInAnyBuildingIdsOrTheirDescendants,
        },
        sortBy: sortByForMeetingServicesQuery
          ? sortByForMeetingServicesQuery
          : undefined,
      },
    },
    skip:
      loadingLocations ||
      availableInAnyBuildingIdsOrTheirDescendants.length < 1,
    notifyOnNetworkStatusChange: true,
  });

  // @TODO: Will need to pass in Kunle's data here
  const { data, refetch: refetchMeetingServicesCount } =
    useGetMeetingServicesCountForTablePaginationQuery({
      variables: {
        countMeetingInput: {
          filters: {
            ...filtersForMeetingServicesQuery,
            availableInAnyBuildingIdsOrTheirDescendants,
          },
        },
      },
      skip:
        loadingLocations ||
        availableInAnyBuildingIdsOrTheirDescendants.length < 1,
    });

  return (
    <MeetingServicesContext.Provider
      value={{
        meetingServicesData,
        refetchMeetingServices: (filters?: RefetchFilters) => {
          refetchMeetingServices({
            input: {
              first: ITEMS_PER_TABLE_PAGE,
              filters: filtersForMeetingServicesQuery,
              sortBy: sortByForMeetingServicesQuery || undefined,
              skip: filters?.skip,
              after: filters?.after,
            },
          });
        },
        meetingServicesCount: data?.countMeetingServices.count || 0,
        refetchMeetingServicesCount: () => {
          refetchMeetingServicesCount({
            countMeetingInput: {
              filters: filtersForMeetingServicesQuery,
            },
          });
        },
        loading: loading || loadingLocations,
        filtersForMeetingServicesQuery,
        setFiltersForMeetingServicesQuery,
        sortByForMeetingServicesQuery,
        setSortByForMeetingServicesQuery,
      }}
    >
      {children}
    </MeetingServicesContext.Provider>
  );
};

export const useMeetingServicesContext = (): MeetingServicesValue => {
  return useContext(MeetingServicesContext);
};
