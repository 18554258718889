import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useState,
  FC,
} from 'react';
import { useAuthContext } from './AuthContext';
import Amplitude, { AmplitudeClient } from 'amplitude-js';
import { config } from 'config';
import { AmplitudeEvents } from 'types/amplitude';

type BufferedEvent = {
  eventName: string;
  metadata?: Record<string, unknown>;
};

export type PageType = 'tickets';

type AmplitudeContextValue = {
  trackEvent: (eventName: string, metadata?: Record<string, unknown>) => void;
};

export const AmplitudeContext = createContext<AmplitudeContextValue>({
  trackEvent: () =>
    // eslint-disable-next-line no-console
    console.warn(
      'Tried to send an Amplitude event before AmplitudeContext is ready'
    ),
});

const log = (message: string, ...args: unknown[]) => {
  if (config.robinEnv === 'development') {
    console.log(`[Amplitude] ${message}`, ...args); // eslint-disable-line no-console
  }
};
export const AmplitudeProvider: FC = ({ children }) => {
  const bufferedEventsRef = useRef<BufferedEvent[]>([]);
  const { currentUser, currentOrg } = useAuthContext();
  const [identified, setIdentified] = useState(false);

  const client = useMemo<AmplitudeClient>(() => {
    const client = Amplitude.getInstance('robin-product-metrics');
    client.init(config.amplitudeApiKey);
    return client;
  }, []);

  useEffect(() => {
    if (!currentUser || !currentOrg) {
      return;
    }

    // Identify the user
    const identify = new Amplitude.Identify();
    identify.set('organizationId', currentOrg.id);
    if (currentOrg.slug) {
      identify.set('organizationSlug', currentOrg.slug);
    }
    client.setUserId(currentUser.id);
    client.setGroup('organizationId', currentOrg.id);
    client.identify(identify);
    setIdentified(true);
    log('Client identity set');

    // Flush events that came in before Amplitude had time to initialize
    if (bufferedEventsRef.current.length) {
      log('Flushing buffered events', bufferedEventsRef.current);
      bufferedEventsRef.current.forEach(({ eventName, metadata }) => {
        client.logEvent(eventName, metadata);
      });
      bufferedEventsRef.current = [];
    }
  }, [currentUser, currentOrg, client]);

  const trackEvent = useCallback(
    (eventName: string, metadata?: Record<string, unknown>) => {
      if (!identified) {
        log('Buffering event:', { eventName, metadata });
        bufferedEventsRef.current.push({ eventName, metadata });
      } else {
        log('Tracking event:', { eventName, metadata });
        client.logEvent(eventName, metadata);
      }
    },
    [identified, bufferedEventsRef, client]
  );

  return (
    <AmplitudeContext.Provider value={{ trackEvent }}>
      {children}
    </AmplitudeContext.Provider>
  );
};

type UseAmplitude = () => {
  /**
   * Track when a user visits the tickets page.
   */
  trackPageView: (tab: PageType) => void;
  trackEvent: (event: string, metadata?: Record<string, unknown>) => void;
};

export const useAmplitude: UseAmplitude = () => {
  const { trackEvent } = useContext(AmplitudeContext);

  const trackPageView = (tab: PageType) => {
    trackEvent(AmplitudeEvents.LOADED_DASHBOARD_TAB, {
      tab: 'tickets',
    });
  };

  return {
    trackEvent,
    trackPageView,
  };
};
