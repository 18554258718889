import { useQuestionContext } from 'components/ServiceRequest/contexts/QuestionContext';
import { useServiceRequestContext } from 'components/ServiceRequest/contexts/ServiceRequestContext';

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useQuestionSelect = () => {
  const { t } = useTranslation('MeetingServiceRequest');
  const {
    setServiceRequestAnswers,
    serviceRequestAnswers,
    answersHaveError,
    setAnswersHaveError,
  } = useServiceRequestContext();
  const { selectType, id, required } = useQuestionContext();

  const isOptionSelected = useCallback(
    (optionId: string) => {
      const answers = serviceRequestAnswers.get(id);
      return !!(
        answers &&
        answers?.type === 'choices' &&
        answers.options.has(optionId)
      );
    },
    [id, serviceRequestAnswers]
  );

  const handleUpdateQuantity = useCallback(
    (selectedOptionId: string, quantity: number | null) => {
      setServiceRequestAnswers((prev) => {
        const prevAnswers = structuredClone(prev);
        const prevAnswer = prevAnswers.get(id);

        if (prevAnswer) {
          if (selectType === 'multi' && prevAnswer.type === 'choices') {
            prevAnswer.options.set(selectedOptionId, {
              quantity,
            });

            return prevAnswers;
          }
        }

        prevAnswers.set(id, {
          type: 'choices',
          options: new Map([[selectedOptionId, { quantity }]]),
        });

        return prevAnswers;
      });
    },
    [id, selectType, setServiceRequestAnswers]
  );

  const getOptionQuantity = useCallback(
    (optionId: string) => {
      const serviceRequestAnswer = serviceRequestAnswers.get(id);

      const option =
        serviceRequestAnswer?.type === 'choices'
          ? serviceRequestAnswer.options.get(optionId)
          : undefined;

      // Defaulting the base number to 1
      return option && option.quantity ? option.quantity : 1;
    },
    [id, serviceRequestAnswers]
  );

  const handleSelectOption = useCallback(
    (selectedOptionId: string, quantity: number | null) => {
      setAnswersHaveError(false);

      setServiceRequestAnswers((prev) => {
        const prevAnswers = structuredClone(prev);
        const prevAnswer = prevAnswers.get(id);

        if (prevAnswer) {
          if (selectType === 'multi' && prevAnswer.type === 'choices') {
            if (isOptionSelected(selectedOptionId)) {
              prevAnswer.options.delete(selectedOptionId);

              if (prevAnswer.options.size === 0) {
                prevAnswers.delete(id);
              }

              return prevAnswers;
            }

            prevAnswer.options.set(selectedOptionId, {
              quantity,
            });

            return prevAnswers;
          }
        }

        prevAnswers.set(id, {
          type: 'choices',
          options: new Map([[selectedOptionId, { quantity }]]),
        });

        return prevAnswers;
      });
    },
    [
      id,
      isOptionSelected,
      selectType,
      setAnswersHaveError,
      setServiceRequestAnswers,
    ]
  );

  const error = useMemo(
    () => (answersHaveError && required ? t('errors.required') : undefined),
    [answersHaveError, required, t]
  );

  return {
    handleSelectOption,
    handleUpdateQuantity,
    getOptionQuantity,
    isOptionSelected,
    error,
  };
};
