import React, { createContext, useContext } from 'react';
import {
  useManageMeetingServices,
  useManageMeetingServicesAPI,
} from '../../../components/MeetingServices/hooks/useManageMeetingServices';
import {
  useGetBuildingsWhereUserCanManageMeetingServicesForFormQuery,
  useGetLocationsByOrgIdQuery,
} from 'generated';
import { useAuthContext } from 'contexts';
import { useTranslation } from 'react-i18next';

type MeetingServicesLocationType = {
  inputText: () => string | undefined;
  locationOptions: { label: string; value: string }[];
  loading: boolean;
} & useManageMeetingServicesAPI;

const MeetingServicesLocationContext =
  createContext<MeetingServicesLocationType>({
    inputText: () => '',
    locationOptions: [],
    loading: false,
    organizationLocation: [],
    locationsUserCanManage: [],
    handleSelectBuilding: () => null,
    selectedLocations: [],
    handleSelect: () => null,
    handleDeselectAll: () => null,
  });

export const MeetingServicesLocationProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { t } = useTranslation('MeetingServicePage');
  const { currentOrg, loading: authLoading } = useAuthContext();
  const { data: buildingData, loading: loadingMeetingServiceMangeBuildings } =
    useGetBuildingsWhereUserCanManageMeetingServicesForFormQuery({
      variables: {},
      skip: authLoading,
    });
  const { data: orgData, loading: loadingOrg } = useGetLocationsByOrgIdQuery({
    variables: {
      id: currentOrg?.id || '',
    },
    skip: authLoading,
  });
  const {
    organizationLocation,
    locationsUserCanManage,
    handleSelectBuilding,
    selectedLocations,
    handleSelect,
    handleDeselectAll,
  } = useManageMeetingServices(buildingData, orgData);

  const locationOptions = organizationLocation.map((location) => ({
    label: location.name,
    value: location.id,
  }));

  const inputText = () => {
    if (selectedLocations)
      switch (true) {
        case selectedLocations.length < 1:
          return t(`selectLocation.placeholder`);
        case selectedLocations.length > 1:
          return t(`selectLocation.totalSelected`, {
            total: selectedLocations.length,
          });
        case selectedLocations.length >= organizationLocation.length:
          return t(`selectLocation.allLocationsSelected`);
        default:
          return selectedLocations[0];
      }
  };

  return (
    <MeetingServicesLocationContext.Provider
      value={{
        organizationLocation,
        locationsUserCanManage,
        loading:
          loadingMeetingServiceMangeBuildings || loadingOrg || authLoading,
        handleSelectBuilding,
        selectedLocations,
        inputText,
        locationOptions,
        handleDeselectAll,
        handleSelect,
      }}
    >
      {children}
    </MeetingServicesLocationContext.Provider>
  );
};

export const useMeetingServicesLocationContext =
  (): MeetingServicesLocationType => {
    return useContext(MeetingServicesLocationContext);
  };
