import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useCallback } from 'react';
import { UITicketQuestion, buildNewQuestion } from 'types';
import { v4 as uuidv4 } from 'uuid';

export type UseManageQuestionsAPI = {
  handleAddQuestion: () => void;
  getUpdatedQuestionsList: (
    updatedQuestion: UITicketQuestion
  ) => UITicketQuestion[];
};

// Hook for management of lists of questions
export const useManageQuestions = (): UseManageQuestionsAPI => {
  const { setValue, watch } = useFormContext<MeetingServiceType>();
  const questions = watch('questions');

  const getUpdatedQuestionsList = useCallback(
    (updatedQuestion: UITicketQuestion) =>
      questions.map((question) => {
        if (question.id === updatedQuestion.id) {
          return updatedQuestion;
        }
        return question;
      }),
    [questions]
  );

  const handleAddQuestion = useCallback(() => {
    setValue('questions', [...questions, buildNewQuestion(uuidv4())]);
  }, [questions, setValue]);

  return {
    handleAddQuestion,
    getUpdatedQuestionsList,
  };
};
