import React, { CSSProperties, useMemo } from 'react';
type AnimatedSliderProps = {
  selectedElementIndex: number;
  style?: CSSProperties;
  navItemsWidths: number[];
  animationSpeed?: number;
};

export const AnimatedSlider: React.FC<AnimatedSliderProps> = ({
  selectedElementIndex,
  style,
  navItemsWidths,
  animationSpeed = 150,
}) => {
  const transX = useMemo(() => {
    if (navItemsWidths.length === 0) return;
    /*
      To get the correct translation value we add up
      the width of every navItems
      expect the ones after the current nav item
    */
    const endValue =
      selectedElementIndex === 0
        ? 0
        : navItemsWidths.reduce((acc, item, index) => {
            if (index < selectedElementIndex) {
              return acc + item;
            }
            return acc;
          });
    return endValue;
  }, [selectedElementIndex, navItemsWidths]);
  const animatedStyle: CSSProperties = {
    transform: `translateX(${transX}px)`,
    transition: `all ${animationSpeed}ms ease-in-out`,
    transitionProperty: 'width transform',
    width: navItemsWidths.length ? navItemsWidths[selectedElementIndex] : 0,
    ...style,
  };
  const blockStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
  };
  const switchesStyle: CSSProperties = {
    alignItems: 'center',
  };
  return <div style={{ ...blockStyle, ...switchesStyle, ...animatedStyle }} />;
};
