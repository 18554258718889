import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { GroupSolid } from '@robinpowered/icons';

type Props = {
  size?: 'small' | 'default';
};

export const GroupAvatar = ({ size = 'default' }: Props): JSX.Element => {
  const containerSize = size === 'small' ? 28 : 40;
  const iconSize = size === 'small' ? 12 : 24;

  return (
    <GroupAvatarContainer size={containerSize}>
      <GroupSolid height={iconSize} width={iconSize} />
    </GroupAvatarContainer>
  );
};

const GroupAvatarContainer = styled.div<{ size: number }>`
  border-radius: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.Gray10};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;
