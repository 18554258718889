import { ServiceTableColumnNames, TableDataType } from '../Table';
import { useCallback, useMemo, useState } from 'react';

export const useTablePagination = (
  data: TableDataType<ServiceTableColumnNames>[]
) => {
  const itemsPerPage = 15;
  const numPages = Math.ceil(data.length / itemsPerPage);
  const [activePage, setActivePage] = useState(1);
  const paginatedData = useMemo(() => {
    if (data.length <= itemsPerPage) {
      return data || [];
    }

    return data.reduce<TableDataType<ServiceTableColumnNames>[][]>(
      (resultArray, item, index) => {
        const chunkIndex = Math.floor(index / itemsPerPage);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      },
      []
    )[activePage - 1];
  }, [activePage, data]);

  const handleClickPrevious = useCallback(
    () => setActivePage((prev) => (prev === 0 ? 0 : prev - 1)),
    [setActivePage]
  );
  const handleClickNext = useCallback(
    () => setActivePage((prev) => (prev === numPages ? numPages : prev + 1)),
    [setActivePage, numPages]
  );
  const handlePageNumberClick = useCallback(
    (pageNum: number) => setActivePage(pageNum),
    []
  );

  return {
    paginatedData,
    activePage,
    handleClickPrevious,
    handleClickNext,
    handlePageNumberClick,
    itemsPerPage,
  };
};
