import { BaseOption } from '../../BaseOption/BaseOption';
import { QuestionTypeContainer } from './QuestionTypeContainer';
import { useQuestionSelect } from '../hooks/useQuestionSelect';
import { useQuestionContext } from 'components/ServiceRequest/contexts/QuestionContext';

export const QuestionSelect = () => {
  const { prompt, required, options, selectType } = useQuestionContext();
  const {
    handleSelectOption,
    handleUpdateQuantity,
    getOptionQuantity,
    isOptionSelected,
    error,
  } = useQuestionSelect();
  return (
    <QuestionTypeContainer title={prompt} required={required} error={error}>
      {options &&
        options.map((option, index) => {
          return (
            <BaseOption
              key={option.id}
              selectType={selectType ? selectType : 'single'}
              option={{
                id: option.id,
                name: option.name,
                imageUrl: option.imageUrl || undefined,
                description: option.description || undefined,
                quantifiable:
                  option.__typename === 'TicketQuestionMenuOption' &&
                  option.quantifiable,
                unitPrice:
                  option.__typename === 'TicketQuestionMenuOption'
                    ? option.unitPrice
                    : undefined,
              }}
              selected={isOptionSelected(option.id)}
              onSelectOption={(quantity: number | null) => {
                handleSelectOption(
                  option.id,
                  option.__typename === 'TicketQuestionMenuOption' &&
                    option.quantifiable
                    ? quantity
                    : null
                );
              }}
              onUpdateQuantity={(quantity: number) => {
                handleUpdateQuantity(
                  option.id,
                  option.__typename === 'TicketQuestionMenuOption' &&
                    option.quantifiable
                    ? quantity
                    : null
                );
              }}
              quantity={getOptionQuantity(option.id)}
              index={index}
            />
          );
        })}
    </QuestionTypeContainer>
  );
};
