import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Colors, TextLink } from '@robinpowered/design-system';
import { useMeetingServicesTableContext } from 'components/ServicesTable/contexts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const BulkActionBanner = ({
  selectedMeetingServicesIds,
}: {
  selectedMeetingServicesIds: Set<string>;
}) => {
  const { t } = useTranslation('MeetingServicePage');
  const { setShowConfirmDelete } = useMeetingServicesTableContext();

  const totalSelectedMeetingServices = selectedMeetingServicesIds.size;
  const [showBanner, setShowBanner] = useState(false);
  const [closeAnimationDone, setCloseAnimationDone] = useState(true);

  useEffect(() => {
    setShowBanner(totalSelectedMeetingServices > 0);
  }, [totalSelectedMeetingServices, setShowBanner]);

  useEffect(() => {
    if (showBanner) {
      setCloseAnimationDone(false);
    }
  }, [showBanner, setCloseAnimationDone]);

  return (
    <>
      {!closeAnimationDone && (
        <Wrapper
          showBanner={showBanner}
          onAnimationEnd={() => {
            if (!showBanner) {
              setCloseAnimationDone(true);
            }
          }}
        >
          {showBanner && (
            <BannerText>
              {t(`table.bulk_action_banner.selected`, {
                count: totalSelectedMeetingServices,
              })}
              <TextLink
                label={t(`table.bulk_action_banner.delete_all`)}
                onClick={() => setShowConfirmDelete(true)}
                variant="inverted"
              />
            </BannerText>
          )}
        </Wrapper>
      )}
    </>
  );
};

/* Why not 🤷🏾‍♂️ */
const sideOpenDown = keyframes`
  from {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  to {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const sideCloseUp = keyframes`
  from {
    padding: 16px;
  }
  to {
    padding: 0px;
  }
`;

const Wrapper = styled.div<{
  showBanner: boolean;
}>`
  width: 100%;
  display: flex;
  padding: 0px 16px;
  background: ${Colors.Blue110};
  color: ${Colors.White0};

  animation: ${(props) => (!props.showBanner ? sideCloseUp : sideOpenDown)};
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    line-height: 0px;
  }
  to {
    opacity: 1;
    line-height: 16px;
  }
`;

const BannerText = styled.div`
  display: flex;
  animation: ${fadeIn};
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  line-height: 0px;
`;
