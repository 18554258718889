import { createContext, useContext, FC, ReactNode } from 'react';
import {
  GetBuildingByIdForServiceBuilderQuery,
  useGetBuildingByIdForServiceBuilderQuery,
} from 'generated';
import {
  ColumnType,
  ServiceTableColumnNames,
  TableDataType,
} from '../components';
import {
  UseTableFiltersAPI,
  useManageBuildingServiceTable,
  useTableFilters,
} from '../hooks';
import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';

type SelectedBuildingTableValue = {
  tableData: TableDataType<ServiceTableColumnNames>[];
  columns: ColumnType[];
  loading: boolean;
  selectedSpaceIds: Set<string>;
  allSpaces:
    | NonNullable<
        GetBuildingByIdForServiceBuilderQuery['getLocationById']
      >['spaces']
    | undefined;
  handleDeselectAllSpaces: () => void;
} & UseTableFiltersAPI;

const SelectedBuildingTableContext = createContext<SelectedBuildingTableValue>({
  tableData: [],
  columns: [],
  loading: true,
  floorOptions: [],
  selectedFloors: [],
  onSelectFloorOption: () => null,
  setSearchValue: () => '',
  searchValue: '',
  selectedSpaceTypes: [],
  onSelectSpaceType: () => null,
  spaceTypeOptions: [],
  filteredData: undefined,
  selectedSpaceIds: new Set(),
  allSpaces: [],
  handleDeselectAllSpaces: () => null,
  handleClearFilters: () => null,
});

type Props = {
  children: ReactNode;
  buildingId: string;
};

export const SelectedBuildingTableContextProvider: FC<Props> = ({
  buildingId,
  children,
}) => {
  const { getValues } = useFormContext<MeetingServiceType>();
  const { data, loading } = useGetBuildingByIdForServiceBuilderQuery({
    variables: { id: buildingId },
  });

  const selectedBuildings = getValues('availableInSpacesByBuilding');

  const { filteredData, ...tableFilterAPI } = useTableFilters(data);
  const { tableData, columns, selectedSpaceIds, deselectAllSpaces } =
    useManageBuildingServiceTable(filteredData, buildingId, selectedBuildings);

  return (
    <SelectedBuildingTableContext.Provider
      value={{
        tableData,
        columns,
        loading,
        filteredData,
        selectedSpaceIds,
        handleDeselectAllSpaces: deselectAllSpaces,
        allSpaces: data?.getLocationById?.spaces?.map((s) => s),
        ...tableFilterAPI,
      }}
    >
      {children}
    </SelectedBuildingTableContext.Provider>
  );
};

export const useSelectedBuildingTableContext =
  (): SelectedBuildingTableValue => {
    return useContext(SelectedBuildingTableContext);
  };
