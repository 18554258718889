/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useMeetingServicesTableContext } from '../contexts';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

export const ConfirmDelete = () => {
  const { t } = useTranslation('MeetingServicePage');
  const {
    selectedMeetingServices,
    setShowConfirmDelete,
    meetingServicesById,
    onDeleteMeetingServices,
    deletingServices,
    setMeetingServicesById,
  } = useMeetingServicesTableContext();

  const confirmTitle =
    selectedMeetingServices.size < 2
      ? t('confirm_delete.single.title', {
          serviceName: meetingServicesById.get(
            selectedMeetingServices.values().next().value
          )?.meetingService.name,
        })
      : t('confirm_delete.multiple.title', {
          totalServices: selectedMeetingServices.size,
        });

  const confirmDescription =
    selectedMeetingServices.size < 2
      ? t('confirm_delete.single.description')
      : t('confirm_delete.multiple.description');

  const deselectMeetingServices = () => {
    if (selectedMeetingServices.size === 1) {
      const selectedService = selectedMeetingServices.values().next().value;
      setMeetingServicesById((prev) => {
        const current = structuredClone(prev);
        return new Map(
          current.set(selectedService, {
            ...current.get(selectedService)!,
            selected: false,
          })
        );
      });
    }
    setShowConfirmDelete(false);
  };

  return (
    <Modal
      title={confirmTitle}
      okText={t('confirm_delete.delete')}
      okType={'danger'}
      onCancel={deselectMeetingServices}
      onOk={onDeleteMeetingServices}
      confirmLoading={deletingServices}
      open={true}
    >
      {confirmDescription}
    </Modal>
  );
};
