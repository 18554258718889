import { UITicketQuestionOptionInput, isOptionMenuType } from 'types';
import { useQuestionBuilderContext } from '../../contexts/QuestionBuilderContext';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useTranslation } from 'react-i18next';
import Graphemer from 'graphemer';

const MAX_OPTION_LENGTH = 200;

export const useManageOption = (
  option: UITicketQuestionOptionInput,
  index: number
) => {
  const { onUpdateOptions, getUpdatedOptions, onDeleteOption, question } =
    useQuestionBuilderContext();
  const [duplicateName, setDuplicateName] = useState(false);
  const { t } = useTranslation('QuestionsSection');
  const { formState } = useFormContext<MeetingServiceType>();

  const hasQuestionHasOptions = question && 'options' in question;

  const optionError = useMemo(() => {
    if (duplicateName) return t('errors.duplicate_option_name');
    else if (formState.errors.questions && !option.name.trim().length)
      return t('errors.empty_name');

    return null;
  }, [formState.errors.questions, duplicateName, option, t]);

  const onFocusLeave = () => {
    if (duplicateName) {
      onUpdateOptions(
        getUpdatedOptions(
          {
            ...option,
            name: '',
          },
          index
        )
      );
    }
    setDuplicateName(false);
  };

  const onChangeName = (name: string) => {
    /* Shallow checks while form is being updated */
    /* @TODO Not fully married to this, maybe something
    with debounce might work better/waiting for onkeyup */
    if (
      name.length &&
      hasQuestionHasOptions &&
      question.options.some((o) => o.name === name)
    ) {
      setDuplicateName(true);
    } else {
      setDuplicateName(false);
    }

    if (new Graphemer().countGraphemes(name) > MAX_OPTION_LENGTH) {
      return;
    }

    const newOptions = getUpdatedOptions(
      {
        ...option,
        name,
      },
      index
    );

    onUpdateOptions(newOptions);
  };

  const onUpdateUnitPrice = (unitPrice: string, currencyCode: string) => {
    if (isOptionMenuType(option)) {
      const newOptions = getUpdatedOptions(
        {
          ...option,
          unitPrice: {
            amountMajor: unitPrice,
            currencyCode,
          },
        },
        index
      );
      onUpdateOptions(newOptions);
    }
  };

  const onToggleQuantity = (quantifiable: boolean) => {
    if (isOptionMenuType(option)) {
      const newOptions = getUpdatedOptions(
        {
          ...option,
          quantifiable,
        },
        index
      );
      onUpdateOptions(newOptions);
    }
  };

  const onChangeDescription = (description: string | null) => {
    const newOptions = getUpdatedOptions(
      {
        ...option,
        description,
      },
      index
    );

    onUpdateOptions(newOptions);
  };
  const onChangeImage = (imageUrl: string | null) => {
    const newOptions = getUpdatedOptions(
      {
        ...option,
        imageUrl,
      },
      index
    );

    onUpdateOptions(newOptions);
  };

  return {
    onChangeName,
    onChangeDescription,
    onChangeImage,
    onToggleQuantity,
    onUpdateUnitPrice,
    onDelete: () => onDeleteOption(option),
    onFocusLeave,
    optionError,
  };
};
