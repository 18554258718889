import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Body, Colors, FieldError, Heading } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';

type QuestionTypeContainerProps = {
  title: string;
  required: boolean;
  error?: string;
};

export const QuestionTypeContainer: FC<QuestionTypeContainerProps> = ({
  title,
  required,
  error,
  children,
}) => {
  const { t } = useTranslation('MeetingServiceRequest');
  return (
    <QuestionContainer error={!!error}>
      <QuestionsHeader>
        <Heading.Small>{title}</Heading.Small>
        {!required && (
          <Body.XSmall style={{ color: Colors.Gray60 }}>
            {t('optional')}
          </Body.XSmall>
        )}
      </QuestionsHeader>
      {children}

      {error && <FieldError errorText={error} />}
    </QuestionContainer>
  );
};

const QuestionContainer = styled.div<{ error: boolean }>`
  ${({ error }) =>
    error
      ? `border: 1px solid ${Colors.Red100};
        padding: 8px;
        border-radius: 4px;`
      : ``}
`;

const QuestionsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 8px;
  align-items: center;
`;
