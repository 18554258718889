import { QueryResult } from '@apollo/client';
import { useAuthContext } from 'contexts';
import {
  Exact,
  GetMeetingServiceByIdForEditScreenQuery,
  useGetMeetingServiceByIdForEditScreenQuery,
} from 'generated';
import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';

export const ServiceFormTabs = ['meetingService', 'categories'] as const;
export type ServiceFormTabTypes = (typeof ServiceFormTabs)[number];

type MeetingServicePageValue = {
  currentPage: ServiceFormTabTypes;
  setCurrentPage: Dispatch<SetStateAction<ServiceFormTabTypes>>;
  serviceId: string;
  serviceFormOpen: boolean;
  setServiceFormOpen: Dispatch<SetStateAction<boolean>>;
  openServiceFormWithId: (serviceId: string) => void;
  getMeetingServiceByIdQuery:
    | QueryResult<
        GetMeetingServiceByIdForEditScreenQuery,
        Exact<{
          getMeetingServiceByIdId: string;
        }>
      >
    | undefined;
};

const MeetingServicePageContext = createContext<MeetingServicePageValue>({
  currentPage: 'meetingService',
  setCurrentPage: () => null,
  serviceId: '',
  serviceFormOpen: false,
  setServiceFormOpen: () => null,
  openServiceFormWithId: () => null,
  getMeetingServiceByIdQuery: undefined,
});

type Props = {
  children: ReactNode;
};

export const MeetingServicePageContextProvider: FC<Props> = ({ children }) => {
  const [currentPage, setCurrentPage] =
    useState<ServiceFormTabTypes>('meetingService');
  const { loading: loadingAuth } = useAuthContext();
  const [serviceFormOpen, setServiceFormOpen] = useState(false);
  const [serviceId, setServiceId] = useState('');

  const openServiceFormWithId = (serviceId: string) => {
    setServiceId(serviceId);
    setServiceFormOpen(true);
  };

  const getMeetingServiceByIdQuery = useGetMeetingServiceByIdForEditScreenQuery(
    {
      variables: {
        getMeetingServiceByIdId: serviceId,
      },
      skip: !serviceId || loadingAuth,
    }
  );
  return (
    <MeetingServicePageContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        serviceId,
        serviceFormOpen,
        setServiceFormOpen,
        openServiceFormWithId,
        getMeetingServiceByIdQuery,
      }}
    >
      {children}
    </MeetingServicePageContext.Provider>
  );
};

export const useMeetingServicePageContext = (): MeetingServicePageValue => {
  return useContext(MeetingServicePageContext);
};
