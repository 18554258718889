import styled from '@emotion/styled';
import {
  Avatar,
  Body,
  Colors,
  Flex,
  Search,
  Skeleton,
} from '@robinpowered/design-system';
import {
  FacilitatorGroup,
  FacilitatorUser,
} from '../../sections/usePersonGroupSearch';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { buildThumbImage } from 'lib/images';
import { GroupAvatar } from 'components/common/GroupAvatar';

type PersonGroupSearchProps = {
  users: FacilitatorUser[];
  groups: FacilitatorGroup[];
  keyword: string;
  onChange: (keyword: string) => void;
  selectGroup: (group: FacilitatorGroup) => void;
  selectIndividual: (user: FacilitatorUser) => void;
  loading: boolean;
  onClose: () => void;
};

export const PersonGroupSearch = ({
  users,
  groups,
  keyword,
  onChange,
  selectGroup,
  selectIndividual,
  loading,
  onClose,
}: PersonGroupSearchProps): JSX.Element => {
  const { t } = useTranslation('ServiceSection');

  /* For outside click */
  const wrapperRef = useRef<HTMLDivElement>(null);
  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    },
    [onClose]
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);
  return (
    <AutoCompleteWrapper ref={wrapperRef}>
      <SearchInput
        buttonLabel={''}
        name={t('facilitator.add_person_group')}
        placeholder={t('facilitator.add_person_group')}
        value={keyword}
        onChange={onChange}
      />

      {loading ? (
        <LoaderWrapper>
          <Loader>
            <Skeleton
              width="24px"
              height="24px"
              radius="100%"
              style={{ borderRadius: '100%' }}
            />
            <Skeleton width="100%" height="24px" />
          </Loader>
        </LoaderWrapper>
      ) : (
        <>
          {!!users.length || !!groups.length ? (
            <SearchResults>
              {groups.map((group, index) => {
                const groupName = `${group.name} (${group.members.length})`;
                return (
                  <SearchResult
                    tabIndex={0}
                    key={index}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        selectGroup(group);
                        onClose();
                      }
                    }}
                    onClick={() => {
                      selectGroup(group);
                      onClose();
                    }}
                  >
                    <Flex
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <GroupAvatar />
                    </Flex>
                    <SearchDetails>
                      <Body.Small>{groupName}</Body.Small>
                      <Body.XSmall>{t('facilitator.group')}</Body.XSmall>
                    </SearchDetails>
                  </SearchResult>
                );
              })}
              {users.map((user, index) => {
                return (
                  <SearchResult
                    tabIndex={0}
                    key={index}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        selectIndividual(user);
                        onClose();
                      }
                    }}
                    onClick={() => {
                      selectIndividual(user);
                      onClose();
                    }}
                  >
                    <Flex
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Avatar
                        name={user.name || ''}
                        src={buildThumbImage(user?.avatar)}
                        size={40}
                      />
                    </Flex>
                    <SearchDetails>
                      <Body.Small>{user.name || 'username'}</Body.Small>
                      <Body.XSmall>{user.primaryEmail?.email}</Body.XSmall>
                    </SearchDetails>
                  </SearchResult>
                );
              })}
            </SearchResults>
          ) : (
            <>
              {keyword.length > 2 && (
                <NoResults>
                  <Body.Small>{t('facilitator.no_results')}</Body.Small>
                </NoResults>
              )}
            </>
          )}
        </>
      )}
    </AutoCompleteWrapper>
  );
};

const SearchInput = styled(Search)`
  input {
    padding-right: 36px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  background-color: ${Colors.White0};
  border: 1px solid ${Colors.Gray30};
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 200px;
  overflow-y: auto;
  padding: 2px;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px 12px;
  width: 100%;
  cursor: pointer;
  align-items: center;
`;

const AutoCompleteWrapper = styled.div`
  width: 390px;
  position: relative;
`;

const SearchResults = styled.div`
  position: absolute;
  background-color: ${Colors.White0};
  border: 1px solid ${Colors.Gray30};
  border-radius: 4px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  max-height: 200px;
  overflow-y: auto;
  padding: 2px;
  z-index: 3;
`;

const SearchResult = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px 12px;

  cursor: pointer;
  &:hover {
    background-color: ${Colors.Gray5};
  }
`;

const SearchDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoResults = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;

  position: absolute;
  background-color: ${Colors.White0};
  border: 1px solid ${Colors.Gray30};
  border-radius: 4px;
  width: 100%;
  min-height: 100px;
  overflow-y: auto;
  z-index: 3;
`;
