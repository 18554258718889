import { useTranslation } from 'react-i18next';
import { ButtonV4, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { useState } from 'react';
import { Name } from './Name';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useFormContext } from 'react-hook-form';
import { Key } from './Key';

type Props = {
  onSave: (categoryName: string, categoryKey: string) => void;
  onCancel: () => void;
};

export const AddCategory = ({ onSave, onCancel }: Props) => {
  const { t } = useTranslation('ServiceSection');
  const { clearErrors, formState } = useFormContext<MeetingServiceType>();
  const [name, setName] = useState('');
  const [key, setKey] = useState('');

  return (
    <Wrapper>
      <Name
        value={name}
        onChange={(value) => {
          if (formState.errors.category?.name?.message) {
            clearErrors('category.name');
          }
          setName((prevName) => (value.length > 70 ? prevName : value));
        }}
        error={formState.errors.category?.name?.message}
      />

      <Key
        value={key}
        onChange={(value) => {
          if (formState.errors.category?.key?.message) {
            clearErrors('category.key');
          }
          setKey((prevKey) =>
            value.length > 5 ? prevKey : value.toUpperCase()
          );
        }}
        error={formState.errors.category?.key?.message}
        label={t(`service_details.inputs.category.key.info`)}
      />
      <ActionsWrapper>
        <ButtonV4
          label={t(`service_details.save`)}
          variant="secondary"
          onClick={() => onSave(name.trim(), key.trim())}
        />
        <ButtonV4
          label={t(`service_details.cancel`)}
          variant="tertiary"
          onClick={onCancel}
        />
      </ActionsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: ${Colors.Gray10};
  padding: 12px;
  border-radius: 8px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  margin-top: 12px;
  gap: 4px;
`;
