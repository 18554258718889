import {
  WorkplaceServiceFormRequest,
  WorkplaceServiceRequestParentAPI,
  useManageChildPenpalMessages,
  RequestMeetingServiceForDraftEventAtSpaceFormReturnValue,
} from '@robinpowered/dashboard-apps-common';
import {
  AnsweredTicketQuestionInput,
  useRequestMeetingServiceForDraftEventAtSpaceForServiceRequestMutation,
} from 'generated';
import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Answers } from '../types/ServiceRequestTypes';
import { GetMeetingServiceByIdForServiceRequestQuestions } from '../contexts/ServiceRequestContext';
import { debounce } from 'lodash';

type Options = {
  meetingServiceId: string | undefined;
  meetingVersionId: string | undefined;
  meetingServiceQuestions:
    | GetMeetingServiceByIdForServiceRequestQuestions
    | undefined;
  answers: Answers;
  totalPrice: string | undefined;
};

export const useConnectToParent = (
  options: Options,
  isDataValid: () => boolean
) => {
  const { t } = useTranslation('MeetingServiceRequest');
  const [initialValues, setInitialValues] =
    useState<WorkplaceServiceFormRequest | null>(null);

  const [requestMeetingServiceForDraftEventAtSpace] =
    useRequestMeetingServiceForDraftEventAtSpaceForServiceRequestMutation();

  const mapAnswersToRequest = useCallback(
    (answers: Answers): Array<AnsweredTicketQuestionInput> => {
      const mappedAnswers = [...answers.entries()].map(
        ([questionId, answer]) => {
          if (answer.type === 'text') {
            return {
              text: {
                questionId,
                answer: answer.text,
              },
            };
          }

          // Check the original question type to see if the answer should be of
          // type TicketAnswerExpectingMenuChoicesInput
          const associatedQuestion =
            options?.meetingServiceQuestions &&
            options?.meetingServiceQuestions.find(
              (q) =>
                q.__typename === 'TicketQuestionExpectingMenuChoices' &&
                q.id === questionId
            );

          if (associatedQuestion) {
            return {
              menuChoices: {
                questionId,
                choices: [...answer.options.entries()].map(
                  ([optionId, { quantity }]) => {
                    return {
                      optionId,
                      quantity,
                    };
                  }
                ),
              },
            };
          }

          // Otherwise they are TicketAnswerExpectingChoicesInput
          return {
            choices: {
              questionId,
              optionIds: [...answer.options.keys()],
            },
          };
        }
      );

      return mappedAnswers;
    },
    [options?.meetingServiceQuestions]
  );

  const requestMeetingService =
    useCallback(async (): Promise<RequestMeetingServiceForDraftEventAtSpaceFormReturnValue> => {
      try {
        if (
          initialValues &&
          options.meetingServiceId &&
          options.meetingVersionId &&
          initialValues.event.type === 'draft'
        ) {
          // Validate info and prevent request if it fails
          if (!isDataValid()) {
            throw t(`errors.default`);
          }

          const value = await requestMeetingServiceForDraftEventAtSpace({
            variables: {
              input: {
                answers: mapAnswersToRequest(options.answers),
                eventEnd: {
                  timeZone: initialValues.event.timezone,
                  // @TODO: probably better for the parent to pass it through in ISO8601
                  dateTime: moment(initialValues.event.endTime).toISOString(),
                },
                eventStart: {
                  timeZone: initialValues.event.timezone,
                  dateTime: moment(initialValues.event.startTime).toISOString(),
                },
                eventUid: initialValues.event.uid,
                meetingServiceId: options.meetingServiceId,
                spaceId: initialValues.spaceId,
                versionId: options.meetingVersionId,
              },
            },
          });

          const data = value.data?.requestMeetingServiceForDraftEventAtSpace;

          if (
            data?.__typename ===
            'RequestMeetingServiceForDraftEventAtSpaceErrorResponse'
          ) {
            // @TODO: map the error reasons to more frontend-y reasons, unless they already are.
            // Though we would still need to for the translations
            return {
              type: 'error',
              translatedReason: t(`errors.default`),
            };
          }

          if (
            data?.__typename ===
            'RequestMeetingServiceForDraftEventAtSpaceSuccessResponse'
          ) {
            return {
              type: 'success',
              meetingServiceRequestId: data.meetingServiceRequestId,
            };
          }
        }
      } catch (e) {
        return {
          type: 'error',
          translatedReason: t(`errors.default`),
        };
      }
      return {
        type: 'error',
        translatedReason: t(`errors.default`),
      };
    }, [
      t,
      initialValues,
      options.meetingServiceId,
      options.meetingVersionId,
      options.answers,
      isDataValid,
      requestMeetingServiceForDraftEventAtSpace,
      mapAnswersToRequest,
    ]);

  const { parent } =
    useManageChildPenpalMessages<WorkplaceServiceRequestParentAPI>({
      requestMeetingServiceForDraftEventAtSpace: requestMeetingService,
      // Heartbeat method (see types for full description)
      pingChild: () => null,
    });

  useEffect(() => {
    const debouncedCall = debounce(() => {
      if (parent) {
        parent.onTotalPriceChange(options.totalPrice);
      }
    }, 200);

    if (parent) {
      debouncedCall();
    }

    return () => {
      debouncedCall.cancel();
    };
  }, [options.totalPrice, parent]);

  useEffect(() => {
    let shouldSetValues = true;
    const getValues = async () => {
      const values = await parent?.getInitialValues();
      if (values && shouldSetValues) {
        setInitialValues(values);
      }
    };
    getValues();
    return () => {
      shouldSetValues = false;
    };
  }, [parent]);

  return { initialValues };
};
