export type SelectType = 'multi' | 'single';

type QuestionId = string;
type OptionId = string;
export type Options = Map<OptionId, { quantity: number | null }>;
type TextAnswer = { type: 'text'; text: string };
type ChoicesAnswer = { type: 'choices'; options: Options };
export type Answer = TextAnswer | ChoicesAnswer;
export type Answers = Map<QuestionId, Answer>;

/* Calculates a value that increases but as a decreasing rate as the dynamicValue increases  */
export const calcLogarithmicGrowth = (
  staticValue: number,
  plateau: number,
  speed: number,
  dynamicValue: number
) => {
  return staticValue + plateau * Math.log(1 + speed * dynamicValue);
};
