import styled from '@emotion/styled';
import { Colors, SpinnerLoader } from '@robinpowered/design-system';
import { DeselectBanner, Filters, Table } from './components';
import { useSelectedBuildingTableContext } from './contexts';

export const SelectedBuildingTable = () => {
  const { tableData, columns, loading, selectedSpaceIds } =
    useSelectedBuildingTableContext();

  return (
    <Wrapper>
      <TableWrapper>
        {loading ? (
          <SpinnerLoader />
        ) : (
          <>
            {selectedSpaceIds.size > 0 && <DeselectBanner />}
            <Filters />
            <Table data={tableData} columns={columns} />
          </>
        )}
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  border-radius: 0 4px;
  background-color: ${Colors.Gray5};
`;

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  flex-direction: column;
  display: flex;
  min-height: 200px;
`;
