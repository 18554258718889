var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useRef, useState } from 'react';
/**
 * This hook allows us to pass methods with updated internal values
 * to the parent connection without needing to re-establish the connection
 * which was causing many re-renders when the method was called
 */
export var useIndirectedMethods = function (methods) {
    var _a = __read(useState(methods), 1), initialMethods = _a[0];
    var methodsRef = useRef(methods);
    useEffect(function () {
        methodsRef.current = methods;
    }, [methods]);
    var indirectedMethods = Object.fromEntries(Object.entries(initialMethods).map(function (_a) {
        var _b = __read(_a, 2), name = _b[0], meth = _b[1];
        return [
            name,
            function () {
                var _a;
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return (_a = methodsRef.current)[name].apply(_a, __spreadArray([], __read(args), false));
            },
        ];
    }));
    return indirectedMethods;
};
