import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useSelectBuildingsContext } from '../../../../../contexts';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMeetingServicePageContext } from 'pages/MeetingServicePage/contexts/MeetingServicePageContext';
import { useGetSpaceIdsForMeetingServiceAvailableInBuildingLazyQuery } from 'generated';

export const useManageBuildingDropdown = (buildingId: string) => {
  const { setValue, getValues } = useFormContext<MeetingServiceType>();
  const { buildings: buildingsQuery } = useSelectBuildingsContext();
  const { getMeetingServiceByIdQuery, serviceId } =
    useMeetingServicePageContext();

  const [getSpaceIds] =
    useGetSpaceIdsForMeetingServiceAvailableInBuildingLazyQuery({
      variables: {
        id: serviceId,
        buildingId,
      },
    });

  const buildings = useMemo(
    () =>
      buildingsQuery?.data?.listBuildingsWhereUserCanManageMeetingServices
        .buildings,
    [buildingsQuery]
  );
  const building = useMemo(
    () => buildings?.find((building) => building?.id === buildingId),
    [buildingId, buildings]
  );

  const totalSpacesSelectedInBuilding =
    getValues('availableInSpacesByBuilding').get(buildingId)?.size ??
    // eslint-disable-next-line max-len
    getMeetingServiceByIdQuery?.data?.getMeetingServiceById.meetingService?.availabilityInBuildings.buildingAvailability.find(
      (b) => b.buildingId === buildingId
    )?.spaceCount ??
    0;

  const handleClickRemove = useCallback(() => {
    // Removes the building from our top level context
    const buildingsSelected = structuredClone(
      getValues('availableInSpacesByBuilding')
    );
    buildingsSelected.delete(buildingId);
    setValue('availableInSpacesByBuilding', buildingsSelected);
  }, [buildingId, getValues, setValue]);

  const handleClickDropdown = useCallback(() => {
    // Undefined means it was loaded from the backend and needs to be populated with spaces
    if (
      typeof getValues('availableInSpacesByBuilding').get(buildingId) ===
      'undefined'
    ) {
      getSpaceIds({
        variables: {
          id: serviceId,
          buildingId,
        },
        fetchPolicy: 'network-only',
      }).then((result) => {
        const buildingsSelected = structuredClone(
          getValues('availableInSpacesByBuilding')
        );

        const spacesForBuilding = new Set(
          result.data?.getMeetingServiceById.meetingService?.availabilityInSpacesOfBuilding.availableInSpaceIds
        );

        buildingsSelected.set(buildingId, spacesForBuilding);
        setValue('availableInSpacesByBuilding', buildingsSelected);
      });
    }
  }, [buildingId, getSpaceIds, getValues, serviceId, setValue]);

  return {
    building,
    handleClickRemove,
    totalSpacesSelectedInBuilding,
    handleClickDropdown,
  };
};
