import { ApolloError, gql } from '@apollo/client';
import { useAuthContext } from 'contexts';
import { useGetUsersAndGroupsByKeywordQuery } from 'generated';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

const MIN_KEYWORD_LENGTH = 2;

export type FacilitatorUser = {
  id: string;
  name?: string | null;
  avatar?: string | null;
  primaryEmail: {
    email: string;
  };
};

export type FacilitatorGroup = {
  id: string;
  name: string;
  members: FacilitatorUser[];
};

export type PersonGroupSearchReturnProps = {
  keyword: string;
  setKeyword: (keyword: string) => void;
  users: FacilitatorUser[];
  groups: FacilitatorGroup[];
  loading: boolean;
  error: ApolloError | undefined;
};

export const GET_USERS_AND_GROUPS_BY_KEYWORD = gql`
  query getUsersAndGroupsByKeyword($organizationId: ID!, $keyword: String!) {
    getUsersByKeyword(organizationId: $organizationId, keyword: $keyword) {
      id
      name
      avatar
      primaryEmail {
        email
      }
    }
    getGroupsByKeyword(organizationId: $organizationId, keyword: $keyword) {
      id
      name
      members {
        id
        name
        avatar
        primaryEmail {
          email
        }
      }
    }
  }
`;

export const usePersonGroupSearch = (): PersonGroupSearchReturnProps => {
  const { currentOrg } = useAuthContext();
  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword] = useDebounce(keyword, 500);

  const { data, loading, error } = useGetUsersAndGroupsByKeywordQuery({
    variables: {
      organizationId: currentOrg?.id || '',
      keyword: debouncedKeyword,
    },
    skip: !currentOrg || debouncedKeyword.length < MIN_KEYWORD_LENGTH,
  });

  const users: FacilitatorUser[] =
    data?.getUsersByKeyword.map((u) => {
      return {
        id: u.id,
        name: u.name,
        avatar: u.avatar,
        primaryEmail: {
          email: u.primaryEmail?.email || '',
        },
      };
    }) || [];

  const groups: FacilitatorGroup[] =
    data?.getGroupsByKeyword.map((g) => {
      return {
        id: g.id,
        name: g.name,
        members:
          g.members.map((m) => {
            return {
              id: m.id,
              name: m.name,
              avatar: m.avatar,
              primaryEmail: {
                email: m.primaryEmail?.email || '',
              },
            };
          }) || [],
      };
    }) || [];

  return {
    keyword,
    setKeyword,
    users,
    groups,
    loading,
    error,
  };
};
