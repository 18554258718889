import styled from '@emotion/styled';
import { BuildingsDropdown } from './components/BuildingsDropdown';
import { useSelectBuildingsContext } from '../../contexts';

export const SelectedBuildings = () => {
  const { selectedBuildingIds } = useSelectBuildingsContext();

  return (
    <Wrapper>
      {[...selectedBuildingIds].map((id) => (
        <BuildingsDropdown key={id} buildingId={id} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`;
