import styled from '@emotion/styled';
import { Body, Heading } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { MeetingServicesFilters } from './components/MeetingServicesFilters';
import { ServicesTable } from 'components/ServicesTable';
import { useMeetingServicePageContext } from 'pages/MeetingServicePage/contexts/MeetingServicePageContext';
import { Button } from '@robinpowered/ui-kit';

export const MeetingServices = () => {
  const { t } = useTranslation('MeetingServicePage');
  const { openServiceFormWithId } = useMeetingServicePageContext();

  return (
    <MeetingServiceWrapper>
      <MeetingServicePageHeader>
        <div>
          <Heading.Large>{t('header.title')}</Heading.Large>
          <Body.Regular>{t('header.description')}</Body.Regular>
        </div>

        <div>
          <Button type="primary" onClick={() => openServiceFormWithId('')}>
            {t('header.createService')}
          </Button>
        </div>
      </MeetingServicePageHeader>
      <MeetingServicesFilters />
      <ServicesTable />
    </MeetingServiceWrapper>
  );
};

const MeetingServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;
`;

const MeetingServicePageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
