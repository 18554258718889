import { useTranslation } from 'react-i18next';
import { Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { SectionWrapper } from '../../components';
import { GroupFacilitators, IndividualFacilitators } from './components';
import {
  SelectFacilitatorProvider,
  useSelectFacilitatorContext,
} from './contexts';
import { AddFacilitator } from './components/AddFacilitator';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useFormContext } from 'react-hook-form';
import { EmptyMessage } from 'components/common/EmptyMessage';

export const Assignee = () => {
  const { t } = useTranslation('ServiceSection');
  const { groupFacilitator, individualFacilitator } =
    useSelectFacilitatorContext();
  const { formState } = useFormContext<MeetingServiceType>();
  const totalFacilitators =
    (groupFacilitator?.length ?? 0) + (individualFacilitator?.length ?? 0);

  return (
    <SelectFacilitatorProvider facilitatorType={'assignees'}>
      <SectionWrapper
        title={t('assignee.title')}
        description={t('assignee.description')}
        leftSide={totalFacilitators <= 10 ? <AddFacilitator /> : undefined}
        hasError={!!formState.errors?.assignees?.message}
      >
        <AssigneeList />
      </SectionWrapper>
    </SelectFacilitatorProvider>
  );
};

const AssigneeList = () => {
  const { t } = useTranslation('ServiceSection');
  const { groupFacilitator, individualFacilitator } =
    useSelectFacilitatorContext();
  const { formState } = useFormContext<MeetingServiceType>();

  if (!groupFacilitator?.length && !individualFacilitator?.length) {
    return (
      <>
        <EmptyMessage
          title={t('assignee.no_assignee_title')}
          description={t('assignee.no_assignee_description')}
          errorMessage={formState.errors?.assignees?.message}
        />
      </>
    );
  }

  return (
    <Wrapper>
      {!!individualFacilitator?.length && <IndividualFacilitators />}
      {!!groupFacilitator?.length && <GroupFacilitators />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${Colors.Gray20};
  border-radius: 4px;
`;
