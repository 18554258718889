import { Option } from 'components/ServiceForm/components/MultiSelect';
import { GetBuildingByIdForServiceBuilderQuery } from 'generated';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

export type UseTableFiltersAPI = {
  floorOptions: Option[];
  selectedFloors: Option[];
  onSelectFloorOption: (option: Option) => void;
  setSearchValue: Dispatch<SetStateAction<string>>;
  searchValue: string;
  selectedSpaceTypes: Option[];
  onSelectSpaceType: (option: Option) => void;
  spaceTypeOptions: Option[];
  filteredData: GetBuildingByIdForServiceBuilderQuery | undefined;
  handleClearFilters: () => void;
};

export const useTableFilters = (
  data: GetBuildingByIdForServiceBuilderQuery | undefined
) => {
  const { t } = useTranslation('ServiceSection');
  const [selectedFloors, setSelectedFloors] = useState<Option[]>([]);
  const [selectedSpaceTypes, setSelectedSpaceTypes] = useState<Option[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const levelsWithSpaces = useMemo(() => {
    const levelsWithSpaces =
      data?.getLocationById?.levels.map((level) => {
        const spacesForLevel =
          data?.getLocationById?.spaces.filter((s) => s.levelId === level.id) ||
          [];

        return { ...level, spaces: spacesForLevel };
      }) || [];

    return levelsWithSpaces.filter((l) => l.spaces.length > 0);
  }, [data?.getLocationById?.levels, data?.getLocationById?.spaces]);

  const floorOptions: Option[] = useMemo(() => {
    const levelNames = levelsWithSpaces?.map((l) => l.name).sort() || [];

    return [
      ...levelNames.map((name) => {
        return {
          value:
            data?.getLocationById?.levels.find((l) => l.name === name)?.id ||
            '',
          label: name,
        };
      }),
      // Adding an 'empty' option to filter by floors with no names
      {
        value: t('location.table.filters.floor.none') || '',
        label: t('location.table.filters.floor.none') || '',
      },
    ];
  }, [data?.getLocationById?.levels, levelsWithSpaces, t]);

  const onSelectFloorOption = (option: Option) => {
    setSelectedFloors((floors) => {
      return selectedFloors.find((floor) => floor.value === option.value)
        ? floors.filter((floor) => floor.value !== option.value)
        : [...floors, option];
    });
  };

  const spaceTypeOptions: Option[] = useMemo(() => {
    // Return 'sets' to get unique names from the space types, then map it into
    // the 'Option' type that the dropdown expects for its values/options
    return (
      [...new Set(data?.getLocationById?.spaces?.map((space) => space.type))]
        .sort()
        .map((spaceType) => {
          return {
            value: spaceType || t('location.table.filters.type.none'),
            label: spaceType || t('location.table.filters.type.none'),
          };
        }) || []
    );
  }, [data?.getLocationById?.spaces, t]);

  const onSelectSpaceType = (option: Option) => {
    setSelectedSpaceTypes((prev) => {
      return prev.find((o) => o.value === option.value)
        ? prev.filter((o) => o.value !== option.value)
        : [...prev, option];
    });
  };

  const filteredData: GetBuildingByIdForServiceBuilderQuery | undefined =
    useMemo(() => {
      let allSpaces = data?.getLocationById?.spaces || [];

      const shouldFilterByFloorName = selectedFloors.length > 0;
      const shouldFilterBySpaceType = selectedSpaceTypes.length > 0;
      const shouldFilterBySpaceName = debouncedSearchValue.length > 0;

      if (
        shouldFilterByFloorName ||
        shouldFilterBySpaceType ||
        shouldFilterBySpaceName
      ) {
        allSpaces = allSpaces.filter((s) => {
          const filterSpaceByFloorName = !selectedFloors.find((floor) => {
            return (
              (floor.value.toLocaleLowerCase() ===
                t('location.table.filters.floor.none') &&
                s.levelId === null) ||
              floor.value.toLocaleLowerCase() === s.levelId
            );
          });

          const filterSpaceBySpaceType = !selectedSpaceTypes.find(
            (o) =>
              (o.value === t('location.table.filters.type.none') &&
                s.type === null) ||
              o.value === s.type
          );

          const filterSpaceBySpaceName = !s.name
            .toLocaleLowerCase()
            .includes(debouncedSearchValue.toLocaleLowerCase());

          if (shouldFilterByFloorName && filterSpaceByFloorName) {
            return false;
          }

          if (shouldFilterBySpaceType && filterSpaceBySpaceType) {
            return false;
          }

          if (shouldFilterBySpaceName && filterSpaceBySpaceName) {
            return false;
          }

          return true;
        });
      }

      return data?.getLocationById
        ? {
            ...data,
            getLocationById: {
              ...data.getLocationById,
              id: data?.getLocationById?.id || '',
              levels: data?.getLocationById?.levels,
              spaces: [...allSpaces],
            },
          }
        : undefined;
    }, [data, debouncedSearchValue, selectedFloors, selectedSpaceTypes, t]);

  const handleClearFilters = () => {
    setSearchValue('');
    setSelectedFloors([]);
    setSelectedSpaceTypes([]);
  };

  return {
    floorOptions,
    selectedFloors,
    onSelectFloorOption,
    setSearchValue,
    searchValue,
    selectedSpaceTypes,
    onSelectSpaceType,
    spaceTypeOptions,
    filteredData,
    handleClearFilters,
  };
};
