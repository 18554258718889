import styled from '@emotion/styled';
import { Colors, SpinnerLoader } from '@robinpowered/design-system';
import { ServiceFormContainerWidths } from 'styles';

export const ServiceFormLoader = () => {
  return (
    <LoaderWrapper>
      <SpinnerLoader />
    </LoaderWrapper>
  );
};
const LoaderWrapper = styled.div`
  display: flex;
  max-width: ${ServiceFormContainerWidths};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px auto 40px;
  gap: 16px;
  background-color: ${Colors.White0};
  height: 300px;
`;
