import styled from '@emotion/styled';
import { UtilityButton } from '@robinpowered/design-system';
import {
  AddDescription,
  DragSolid,
  ImageOutline,
  TrashOutline,
} from '@robinpowered/icons';
import { useTranslation } from 'react-i18next';
import { ImageUploadModal } from '../ImageUploadModal';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UITicketQuestionOptionInput, isOptionMenuType } from 'types/tickets';
import { useManageOption, useManageOptionImage } from '../hooks';
import { useMemo } from 'react';
import { DescriptionInput } from './components/DescriptionInput';
import { ImageInput } from './components/ImageInput';
import { Input } from '@robinpowered/ui-kit';
import { QuantityToggle } from './components/QuantityToggle';
import { UnitPrice } from './components/UnitPrice';
import { FieldError } from 'components/ServiceForm/components/Fields';

type OptionProps = {
  option: UITicketQuestionOptionInput;
  // What place it is amongst other options
  index: number;
  showDelete: boolean;
};

export const Option = ({ option, index, showDelete }: OptionProps) => {
  const { t } = useTranslation('QuestionsSection');
  const id = useMemo(() => option.id, [option.id]);
  const {
    onChangeName,
    onChangeDescription,
    onToggleQuantity,
    onUpdateUnitPrice,
    onChangeImage,
    onDelete,
    onFocusLeave,
    optionError,
  } = useManageOption(option, index);
  const {
    imageUploadModalOpen,
    setImageUploadModalOpen,
    handleCloseImageUploadModal,
    openImageModalRef,
    replaceImageRef,
  } = useManageOptionImage();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const isStringDescription = (value: unknown): value is string =>
    typeof value === 'string';

  const description = option.description;
  const imageUrl = option.imageUrl;
  const optionName = option.name;

  return (
    <OptionWrapper ref={setNodeRef} style={style}>
      {imageUploadModalOpen && (
        <ImageUploadModal
          title={
            imageUrl
              ? t('image_upload.replace_image')
              : t('image_upload.add_image')
          }
          onCancel={handleCloseImageUploadModal}
          getUploadedImage={onChangeImage}
        />
      )}
      <InputWrapper>
        <UtilityButton
          icon={DragSolid}
          style={{ cursor: 'move' }}
          {...attributes}
          {...listeners}
        />

        <div onBlur={onFocusLeave} style={{ width: '100%' }}>
          <Input
            name={optionName}
            value={optionName}
            onChange={(e) => onChangeName(e.target.value)}
            placeholder={t('option.placeholder', { order: index + 1 })}
            status={optionError ? 'error' : ''}
          />
          {optionError && <FieldError message={optionError} />}
        </div>

        {description === null && (
          <div title={t('tooltips.description')}>
            <UtilityButton
              icon={AddDescription}
              onClick={() => onChangeDescription('')}
            />
          </div>
        )}
        {!imageUrl && (
          <div ref={openImageModalRef} title={t('tooltips.upload')}>
            <UtilityButton
              icon={ImageOutline}
              onClick={() => setImageUploadModalOpen(true)}
            />
          </div>
        )}
        {showDelete && (
          <div title={t('tooltips.delete')}>
            <UtilityButton icon={TrashOutline} onClick={() => onDelete()} />
          </div>
        )}
      </InputWrapper>

      {isOptionMenuType(option) && (
        <MenuOptionWrapper>
          <QuantityToggle
            onToggle={() => onToggleQuantity(!option.quantifiable)}
            checked={option.quantifiable}
          />

          <UnitPrice
            onUpdateUnitPrice={onUpdateUnitPrice}
            unitPrice={option.unitPrice?.amountMajor?.toString() || '0'}
          />
        </MenuOptionWrapper>
      )}

      {(description !== null || imageUrl) && (
        <ExtraDetailsWrapper>
          {description !== null && isStringDescription(description) && (
            <DescriptionInput
              description={description}
              onChangeDescription={onChangeDescription}
            />
          )}
          {imageUrl && (
            <ImageInput
              setImageUploadModalOpen={setImageUploadModalOpen}
              replaceImageRef={replaceImageRef}
              onChangeImage={onChangeImage}
              name={optionName}
              imageUrl={imageUrl}
            />
          )}
        </ExtraDetailsWrapper>
      )}
    </OptionWrapper>
  );
};

const OptionWrapper = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px 4px;
  padding-left: 25px;
  align-items: start;
  margin-bottom: 16px;
  width: 100%;
  flex-wrap: wrap;
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
`;

const MenuOptionWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-top: 12px;
  padding-left: 36px;
`;

const ExtraDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 36px;
  width: 100%;
  gap: 8px;
`;
