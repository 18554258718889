import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { Dropdown } from './components';
import { SelectedBuildingTable } from './SelectedBuildingTable/SelectedBuildingTable';
import { SelectedBuildingTableContextProvider } from './SelectedBuildingTable/contexts';
import { useState } from 'react';

type Props = {
  buildingId: string;
};

export const BuildingsDropdown = ({ buildingId }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <SelectedBuildingTableContextProvider buildingId={buildingId}>
      <Wrapper>
        <Dropdown
          buildingId={buildingId}
          onClick={() => setIsDropdownOpen((prev) => !prev)}
          isOpen={isDropdownOpen}
        />
        {isDropdownOpen && <SelectedBuildingTable />}
      </Wrapper>
    </SelectedBuildingTableContextProvider>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${Colors.Gray30};
`;
