import { useMemo } from 'react';
import { useServiceRequestContext } from 'components/ServiceRequest/contexts/ServiceRequestContext';

export type AssignedUser = {
  id: string;
  userName?: string;
  avatar?: string | null | undefined;
  primaryEmail?: { email?: string };
};
type AssignedGroup = {
  id: string;
  groupName: string;
  members: {
    id: string;
    name?: string;
    avatar?: string | null | undefined;
    primaryEmail?: { email?: string };
  }[];
};

export const useAssignees = () => {
  const { selectedServiceWithQuestions } = useServiceRequestContext();

  const assignees = useMemo(() => {
    if (
      !selectedServiceWithQuestions ||
      selectedServiceWithQuestions.assignees?.usersAndGroups.length === 0
    ) {
      return undefined;
    }

    // Just finding the first group
    const groups =
      (selectedServiceWithQuestions.assignees?.usersAndGroups.filter(
        (a) => a?.__typename === 'Group'
      ) || []) as AssignedGroup[];
    const users =
      (selectedServiceWithQuestions.assignees?.usersAndGroups.filter(
        (a) => a?.__typename === 'User'
      ) || []) as AssignedUser[];
    return {
      users,
      groups,
    };
  }, [selectedServiceWithQuestions]);

  const avatarStack = useMemo(() => {
    const userAvatars =
      assignees?.users.map((user) => {
        return {
          name: user.userName ? user.userName : user.primaryEmail?.email || '',
          id: user.id,
          size: 24,
          src: user.avatar || '',
        };
      }) || [];

    const groupAvatars =
      assignees?.groups.map((group) => {
        return {
          name: group.groupName,
          id: group.id,
          size: 24,
          src: group.groupName || '',
        };
      }) || [];

    return [...groupAvatars, ...userAvatars];
  }, [assignees?.groups, assignees?.users]);

  return {
    avatarStack,
    assignees,
  };
};
