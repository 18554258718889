import styled from '@emotion/styled';
import { Body, Flex, UtilityButton } from '@robinpowered/design-system';
import { TrashOutline } from '@robinpowered/icons';
import { Description } from 'components/common/Description';
import { useTranslation } from 'react-i18next';

type OptionProps = {
  description: string | null;
  onChangeDescription: (markdownString: string | null) => void;
};

export const DescriptionInput = ({
  description,
  onChangeDescription,
}: OptionProps) => {
  const { t } = useTranslation('QuestionsSection');

  return (
    <RichTextContainer>
      <Body.Small style={{ minWidth: '120px', alignSelf: 'start' }}>
        {t('description.title')}
      </Body.Small>

      <Description
        name={t('description.input')}
        description={description as string}
        onUpdate={onChangeDescription}
        minHeight="60px"
      />

      <div title={t('image_upload.remove')}>
        {/* <DeleteButton
          icon={() => <TrashOutline size={16} color={Colors.Gray60} />}
          onClick={() => onChangeDescription(null)}
          variant="tertiary"
        /> */}

        <UtilityButton
          icon={TrashOutline}
          onClick={() => onChangeDescription(null)}
        />
      </div>
    </RichTextContainer>
  );
};

const RichTextContainer = styled(Flex)`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-basis: 100%;
  width: 100%;
  list-style: disc;

  div {
    &:first-of-type {
      width: 100%;
    }
  }
  ul {
    list-style: disc;
  }
`;
