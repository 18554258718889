import { useTranslation } from 'react-i18next';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useFormContext } from 'react-hook-form';
import { Description } from 'components/common/Description';

export const ServiceDescription = () => {
  const { t } = useTranslation('ServiceSection');
  const { setValue, watch } = useFormContext<MeetingServiceType>();
  const description = watch('description');

  return (
    <Description
      name={t(`service_details.inputs.description.placeholder`)}
      description={description}
      minHeight={'90px'}
      label={t(`service_details.inputs.description.label`)}
      hasTip={true}
      onUpdate={(value) => {
        setValue('description', value);
      }}
    />
  );
};
