import styled from '@emotion/styled';
import { ServiceFormContainerWidths } from 'styles';
import { ServiceDetails, Approver, Assignee, Building } from './sections';

export const ServiceSection = () => {
  return (
    <ServiceSectionWrapper>
      <ServiceDetails />
      <Assignee />
      <Building />
      <Approver />
    </ServiceSectionWrapper>
  );
};

const ServiceSectionWrapper = styled.div`
  display: flex;
  max-width: ${ServiceFormContainerWidths};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px auto 40px;
  gap: 16px;
`;
