import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { FacilitatorGroup, FacilitatorUser } from '../../usePersonGroupSearch';
import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';

export type UseManageFacilitatorsAPI = {
  groupFacilitator: FacilitatorGroup[] | undefined;
  individualFacilitator: FacilitatorUser[] | undefined;
  showPersonGroupSearch: boolean;
  closePersonGroupSearch: () => void;
  selectGroupFacilitator: (group: FacilitatorGroup) => void;
  selectIndividualFacilitator: (individual: FacilitatorUser) => void;
  setShowPersonGroupSearch: Dispatch<SetStateAction<boolean>>;
  removeGroupFacilitator: (index: number) => void;
  removeIndividualFacilitator: (index: number) => void;
};

// @TODO: rm keyword prop should probably be moved outside of params to not
// tie this hook too much to external apis
export const useManageFacilitators = (
  removeKeyword: (keyword: string) => void,
  facilitatorType: 'assignees' | 'approvers'
): UseManageFacilitatorsAPI => {
  const { setValue, clearErrors, watch, getValues } =
    useFormContext<MeetingServiceType>();
  const individualFacilitator = watch(`${facilitatorType}.users`);
  const groupFacilitator = watch(`${facilitatorType}.groups`);
  const allowMultipleSelections = facilitatorType === 'assignees';

  const [showPersonGroupSearch, setShowPersonGroupSearch] = useState(false);

  const closePersonGroupSearch = useCallback(() => {
    setShowPersonGroupSearch(false);
    removeKeyword('');
  }, [removeKeyword, setShowPersonGroupSearch]);

  const removeGroupFacilitator = useCallback(
    (index: number) => {
      const groupFacilitator = getValues(`${facilitatorType}.groups`);
      const newGroup = [...groupFacilitator];
      newGroup.splice(index, 1);

      setValue(`${facilitatorType}.groups`, [...newGroup]);
    },
    [facilitatorType, getValues, setValue]
  );

  const removeIndividualFacilitator = useCallback(
    (index: number) => {
      const individualFacilitator = getValues(`${facilitatorType}.users`);
      const newAssignee = [...individualFacilitator];
      newAssignee.splice(index, 1);
      setValue(`${facilitatorType}.users`, [...newAssignee]);
    },
    [facilitatorType, getValues, setValue]
  );

  const selectGroupFacilitator = useCallback(
    (group: FacilitatorGroup) => {
      if (
        !getValues('approvers.groups').some(
          (groupAssignee) => groupAssignee.id === group.id
        )
      ) {
        // We only allow multiple group selections for assignee facilitator types
        if (allowMultipleSelections) {
          setValue(`${facilitatorType}.groups`, [
            ...getValues(`${facilitatorType}.groups`),
            group,
          ]);
        } else {
          removeIndividualFacilitator(0);
          setValue(`${facilitatorType}.groups`, [group]);
          closePersonGroupSearch();
        }
        clearErrors(facilitatorType);
      }
    },
    [
      getValues,
      allowMultipleSelections,
      clearErrors,
      facilitatorType,
      setValue,
      removeIndividualFacilitator,
      closePersonGroupSearch,
    ]
  );

  const selectIndividualFacilitator = useCallback(
    (user: FacilitatorUser) => {
      if (
        !getValues(`${facilitatorType}.users`).some(
          (individualAssignee) => individualAssignee.id === user.id
        )
      ) {
        if (allowMultipleSelections) {
          setValue(`${facilitatorType}.users`, [
            ...getValues(`${facilitatorType}.users`),
            user,
          ]);
        } else {
          removeGroupFacilitator(0);
          setValue(`${facilitatorType}.users`, [user]);
          closePersonGroupSearch();
        }
        clearErrors(facilitatorType);
      }
    },
    [
      getValues,
      allowMultipleSelections,
      clearErrors,
      facilitatorType,
      setValue,
      removeGroupFacilitator,
      closePersonGroupSearch,
    ]
  );

  return {
    groupFacilitator,
    individualFacilitator,
    showPersonGroupSearch,
    closePersonGroupSearch,
    setShowPersonGroupSearch,
    selectGroupFacilitator,
    selectIndividualFacilitator,
    removeGroupFacilitator,
    removeIndividualFacilitator,
  };
};
