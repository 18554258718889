import { useTranslation } from 'react-i18next';
import { TextInput } from '@robinpowered/design-system';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';

export const Name = () => {
  const { t } = useTranslation('ServiceSection');
  const { setValue, watch, clearErrors, formState } =
    useFormContext<MeetingServiceType>();
  const name = watch('serviceName');

  const handleOnChangeName = useCallback(
    (value: string) => {
      setValue('serviceName', value);
      if (value.length) {
        clearErrors('serviceName');
      }
    },
    [clearErrors, setValue]
  );

  return (
    <TextInput
      name={t(`service_details.inputs.name.label`)}
      label={t(`service_details.inputs.name.label`)}
      value={name}
      placeholder={t(`service_details.inputs.name.placeholder`)}
      onChange={handleOnChangeName}
      error={formState.errors.serviceName?.message}
    />
  );
};
