import { Skeleton } from '@robinpowered/design-system';
import { Row } from './Row';

export const LoadingDetailCard = ({ title }: { title: string }) => {
  return (
    <Row title={title}>
      <Skeleton
        width="16px"
        height="16px"
        style={{ borderRadius: '50%' }}
        hasAnimation={true}
      />
      <Skeleton width={'280px'} height="16px" hasAnimation={true} />
    </Row>
  );
};
