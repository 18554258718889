import { useTranslation } from 'react-i18next';
import { SectionWrapper } from '../../components';
import { SelectBuildingsInput, SelectedBuildings } from './components';
import { SelectBuildingsProvider } from './contexts';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useFormContext } from 'react-hook-form';

export const Building = () => {
  const { t } = useTranslation('ServiceSection');
  const { formState } = useFormContext<MeetingServiceType>();

  return (
    <SelectBuildingsProvider>
      <SectionWrapper
        title={t(`location.title`)}
        description={t(`location.subtitle`)}
        hasError={!!formState.errors?.availableInSpacesByBuilding?.message}
      >
        <SelectBuildingsInput />
        <SelectedBuildings />
      </SectionWrapper>
    </SelectBuildingsProvider>
  );
};
