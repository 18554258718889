import { createContext, useContext, FC } from 'react';
import {
  GetMeetingServiceByIdForServiceRequestQuery,
  TicketQuestionMenuOption,
  TicketQuestionOption,
} from 'generated';
import { SelectType } from '../types/ServiceRequestTypes';

export type selectedServiceWithQuestions =
  | GetMeetingServiceByIdForServiceRequestQuery['getMeetingServiceById']['meetingService']
  | undefined;

type Question = {
  prompt: string;
  required: boolean;
  options?: (TicketQuestionOption | TicketQuestionMenuOption)[];
  id: string;
  __typename?:
    | 'TicketQuestionExpectingChoices'
    | 'TicketQuestionExpectingMenuChoices'
    | 'TicketQuestionExpectingText';
};

type QuestionContextValue = {
  selectType: SelectType;
} & Question;

const QuestionContext = createContext<QuestionContextValue>({
  prompt: '',
  required: false,
  options: [],
  id: '',
  selectType: 'single',
  __typename: 'TicketQuestionExpectingChoices',
});

export const QuestionContextProvider: FC<{
  question: Question;
  selectType?: SelectType;
}> = ({ question, selectType, children }) => {
  return (
    <QuestionContext.Provider
      value={{ ...question, selectType: selectType ? selectType : 'single' }}
    >
      {children}
    </QuestionContext.Provider>
  );
};

export const useQuestionContext = (): QuestionContextValue => {
  return useContext(QuestionContext);
};
