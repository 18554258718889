import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { Add } from '@robinpowered/icons';
import { Button } from '@robinpowered/ui-kit';

type AddItemButtonProps = {
  onClick: () => void;
  title: string;
  ariaLabel?: string;
  disabled?: false;
  style?: React.CSSProperties;
  size?: 'small' | 'middle' | 'large';
};

export const AddItemButton = ({
  onClick,
  title,
  ariaLabel,
  disabled,
  style,
  size = 'middle',
}: AddItemButtonProps): JSX.Element => {
  return (
    <AddButton
      type="text"
      size={size}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      style={style}
    >
      <Add size={size === 'middle' ? 16 : 12} color={Colors.Gray60} />
      {title}
    </AddButton>
  );
};

const AddButton = styled(Button)`
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
  padding: 8px;
`;
