import styled from '@emotion/styled';
import { Body } from '@robinpowered/design-system';
import { Checkbox } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';

type QuantityToggleProps = {
  onToggle: () => void;
  checked: boolean;
};

export const QuantityToggle = ({
  checked,
  onToggle,
}: QuantityToggleProps): JSX.Element => {
  const { t } = useTranslation('QuestionsSection');

  return (
    <ToggleWrapper onClick={onToggle}>
      <Body.Small style={{ minWidth: '120px' }}>
        {t('menu_options.ask_for_quantity')}
      </Body.Small>
      <Checkbox
        checked={checked}
        onChange={onToggle}
        style={{ flexDirection: 'row-reverse' }}
      ></Checkbox>
    </ToggleWrapper>
  );
};

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;
