/* eslint-disable react/display-name */
// @TODO: move this out of the 'common/header' into common/common or comp lib
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Colors } from '@robinpowered/design-system';
import {
  Body05,
  Heading01,
  Heading02,
  Heading03,
  Heading04,
  Heading05,
  Heading06,
  Del,
  Paragraph,
  ListItem,
  UnorderedList,
  Link,
} from './documentComponents';

type MarkdownRendererProps = {
  markdownString: string;
};

// Disables footnotes, tables and checkboxes
const disallowedElements = [
  'sup',
  'table',
  'tbody',
  'td',
  'th',
  'tr',
  'thead',
  'input',
  'code',
];

export const MarkdownRenderer: FC<MarkdownRendererProps> = ({
  markdownString,
}) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({ children, node, ...props }) => (
          <Heading01 {...props} mb={3}>
            {children}
          </Heading01>
        ),
        h2: ({ children, node, ...props }) => (
          <Heading02 {...props} mb={3}>
            {children}
          </Heading02>
        ),
        h3: ({ children, node, ...props }) => (
          <Heading03 {...props} mb={3}>
            {children}
          </Heading03>
        ),
        h4: ({ children, node, ...props }) => (
          <Heading04 {...props} mb={3}>
            {children}
          </Heading04>
        ),
        h5: ({ children, node, ...props }) => (
          <Heading05 {...props} mb={3}>
            {children}
          </Heading05>
        ),
        h6: ({ children, node, ...props }) => (
          <Heading06 {...props} mb={3}>
            {children}
          </Heading06>
        ),
        p: ({ children, node, ...props }) => (
          <Paragraph {...props}>
            <Body05 color={Colors.Gray100} mb={2}>
              {children}
            </Body05>
          </Paragraph>
        ),
        li: ({ children, node, ...props }) => (
          <ListItem {...props}>
            <Body05 color={Colors.Gray100}>{children}</Body05>
          </ListItem>
        ),
        del: ({ children, node, ...props }) => <Del>{children}</Del>,
        ul: ({ children }) => <UnorderedList>{children}</UnorderedList>,
        a: ({ children, ...props }) => (
          <Link target="_blank" {...props}>
            {children}
          </Link>
        ),
      }}
      remarkPlugins={[remarkGfm]}
      disallowedElements={disallowedElements}
    >
      {markdownString}
    </ReactMarkdown>
  );
};
